class CategoriaService {



    async categorias() {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/categorias`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            if (response.status === 200)
                return await response.json();
        } catch (error) {
            console.log(error)
        }
    }

}
export default new CategoriaService();