import React, { Component, useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { IoPersonCircleOutline, IoSettings, IoSettingsOutline, IoSettingsSharp } from "react-icons/io5";
import { BsPersonFill } from "react-icons/bs";
export default function NavMenu(props){

    const location = useLocation();
    const [pagina, setPagina] = useState('');
    const [usuario, setUsuario] = useState(null);
    let history = useHistory();

    useEffect(() => {
   
        setInterval(() => {
            const userString = localStorage.getItem("usuario");
            const user = JSON.parse(userString);
            setUsuario(user);
        }, [])
    }, 5000);

    useEffect(() => {
        init();
    }, [])
    const logOut = async () => {
        await localStorage.removeItem("usuario");
        history.push('/login');
    }
    const init = async () => {
        //var menu = await localStorage.getItem('menu');
        var _usuario = await localStorage.getItem("usuario");
        if (!_usuario) {
            console.log("----teste")
            console.log(_usuario)
            setUsuario(_usuario);
        }

        setPagina(location.pathname.replace('/',''));

       
        console.log(location.pathname);
    }

    const abrirMenu = () =>{
        document.getElementById("navMenu").style.display = "block";
    }
    const fecharMenu = () => {
        document.getElementById("navMenu").style.display = "none";
    }



        return (
            <header>
                <div class="header">
                    <div class="header-logo">
                        <img src={require('../assets/img/logo.png')}  alt="Logo Atvos" />
                    </div>
                    <div onClick={abrirMenu} class="header-icon-mobile">
                        <img src={require('../assets/img/icon-menu.png')}  alt="Ícone do Menu" />
                    </div>
                    <nav style={{ width: usuario && usuario.perfis.filter(x => x.perfil === 'ADM').length > 0 ? '50%':'30%' }}>
                        <ul>
                            <li style={{ width: usuario ? '20%' : '30%' }}><Link to={usuario ? "/usuario" : "/login"}>

                                {usuario ? <BsPersonFill size={'25px'} /> : 'LOGIN'}
                            </Link></li>
                            {
                                usuario && usuario.perfis.filter(x => x.perfil === 'ADM').length > 0 ? <li style={{ width: '20%' }}><Link to='/adm'><IoSettingsSharp size={'25px'} /></Link></li> : null
                            }
                            {usuario && usuario.perfis.filter(x => x.perfil === 'ADM' || x.perfil === 'TR' || x.perfil === 'AV1' || x.perfil === 'AV2').length > 0 ? <li style={{ alignContent: 'center', textAlign: 'center', width: '70%' }}><Link to="/avaliador">ESPAÇO DO AVALIADOR</Link></li> : !usuario ? <li style={{ alignContent: 'center', textAlign: 'center', width: '70%' }}><Link to="/login">ESPAÇO DO AVALIADOR</Link></li> : null}

                            {
                                usuario ? <li style={{ width: usuario && usuario.perfis.filter(x => x.perfil === 'ADM' || x.perfil === 'TR' || x.perfil === 'AV1' || x.perfil === 'AV2').length == 0 ? '80%' : '30%', background: "linear-gradient(0deg, #ff8100 80%, #ff681a 80% 20%)" }}><a onClick={() => logOut()}>SAIR</a></li> : null
                            }
                           
                        </ul>
                    </nav>
                </div>
                <nav id="navMenu" class="navbar">
                    <div onClick={fecharMenu} class="navbar-icon-mobile">
                        <img src={require('../assets/img/icon-fechar.png')}  alt="Icone do Menu" />
                    </div>
                    <ul>
                        <li class={pagina === '' ? "active" : ''}><Link onClick={() => { setPagina('') }} to="/">INÍCIO</Link></li>
                        <li class={pagina === 'categorias' ? "active" : ''}><Link onClick={() => { setPagina('categorias') }} to="/categorias">CATEGORIAS</Link></li>
                        <li class={pagina === 'inscrevase' ? "active" : ''}><Link onClick={() => { setPagina('inscrevase') }} to="/inscrevase">INSCREVA-SE</Link></li>
                        <li class={pagina === 'votacao' ? "active" : ''}><Link onClick={() => { setPagina('votacao') }} to="/votacao">VOTAÇÃO</Link></li>
                        <li class={pagina === 'duvidas' ? "active" : ''}><Link onClick={() => { setPagina('duvidas') }} to="/duvidas">DÚVIDAS</Link></li>
                        <li class={pagina === 'regulamentos' ? "active" : ''}><Link onClick={() => { setPagina('regulamentos') }} to="/regulamentos">REGULAMENTO</Link></li>
                    </ul>

                    <ul  class="menu-navbar-mobile">
                        <li class="login-mobile"><Link to={usuario ? "/usuario" : "/login"}>

                            {usuario ? <BsPersonFill size={'25px'} /> : 'LOGIN'}
                        </Link></li>
                        {
                            usuario && usuario.perfis.filter(x => x.perfil === 'ADM').length > 0 ? <li class="login-mobile"><Link to='/adm'><IoSettingsSharp size={'25px'} /></Link></li> : null
                        }
                        {usuario && usuario.perfis.filter(x => x.perfil === 'ADM' || x.perfil === 'TR' || x.perfil === 'AV1' || x.perfil === 'AV2').length > 0 ? <li style={{ backgroundColor: 'rgb(255,104,26)' }}><Link to="/avaliador">ESPAÇO DO AVALIADOR</Link></li> : !usuario ? <li style={{ backgroundColor: 'rgb(255,104,26)' }}><Link to="/login">ESPAÇO DO AVALIADOR</Link></li> : null}

                        {
                            usuario ? <li class="login-mobile"><a onClick={() => logOut()}>SAIR</a></li> : null
                        }
                    </ul>

                </nav>
            </header>
        );
    
}
