class AvaliacaoService {

    async avaliacoes() {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/avaliacoes`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            if (response.status === 200)
                return await response.json();
        } catch (error) {
            console.log(error)
        }
    }

    async avaliacoesByNivel(nivel) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/avaliacoes/${nivel}`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            if (response.status === 200)
                return await response.json();

        } catch (error) {
            console.log(error)
        }
    }

    async addAvaliacoesTrabalho(avaliacao) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/AvaliacaoTrabalho`,
                {
                    method: 'POST',
                    body: avaliacao,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }


}
export default new AvaliacaoService;