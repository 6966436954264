import React, { useEffect, useState } from 'react';
import platform from 'platform';


export default function RegulamentoPage(props) {

    useEffect(() => {
        var _plataforma = platform.os.family;
        if (
            String(_plataforma).toLocaleLowerCase() === 'ios' ||
            String(_plataforma).toLocaleLowerCase() === 'android'
        ) {
            document.getElementById("navMenu").style.display = "none";
        }
        
        init();

    }, []);
    const init = async () => {

        var usuario = await localStorage.getItem("usuario");
        console.log(usuario)
        if (!usuario) {
            await localStorage.setItem('menu', 'login');
            props.history.push('/login')
        }

    }

    return (

        <>

            <section class="conteudo">
                <h1>
                    Participar do Prêmio Destaque Atvos é muito fácil.
                </h1>
                <p>
                    Leia atentamente as informações sobre o prêmio: etapas, formato de inscrição dos trabalhos, requisitos, critérios de julgamento e premiação.
                </p>
                <a href={require('../../assets/documentos/REGULAMENTO-PREMIO-DESTAQUE-ATVOS-2022.pdf')} download>
                    <div class="botao-laranja">
                        Baixar Regulamento
                    </div>
                </a>
            </section>
            <section class="conteudo fundo-cinza">

                <p>
                    Enquanto preenche a inscrição do seu trabalho, que tal conferir os projetos inscritos nas edições anteriores do Destaque Atvos? Acesse a página Meu Trabalho dentro da Intra Atvos.
                </p>

            </section>

        </>

    );
}