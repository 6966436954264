import React, { useEffect, useState } from 'react';
import { Checkbox, Dialog, DialogContent, FormControlLabel, FormGroup, Tooltip } from '@material-ui/core';
import DialogTitle from '../../../components/DialogTitle';
import usuarioService from '../../../services/usuario/usuario-service';
import avaliacaoService from '../../../services/Avaliacao/avaliacao-service';
import ReactStars from 'react-stars'
import LoadComponent from '../../../components/Load';

export default function AvaliacaoTrabalhoComponent({ idUsuario, isOpen, close, trabalho, nivel }) {

    const [perfis, setPerfis] = useState([]);
    const [avaliacoes, setAvaliacoes] = useState([]);
    const [pesoTotal, setPesoTotal] = useState(1);
    const [itemAvaliado, setItemAvaliado] = useState([]);
    const [notaFinal, setNotaFinal] = useState(0);
    const [load, setLoad] = useState(false);


    useEffect(() => {
        if (isOpen)
            init();

    }, [isOpen]);

    const init = async () => {
        setItemAvaliado([])
        setNotaFinal(0);
        setLoad(false);
        await getAvaliacoes();
    }

    const avaliar = (avaliacao, nota) => {
        console.log(nota)
        var _itemAvaliado = itemAvaliado;
        if (_itemAvaliado.filter((a) => a.id_avaliacao === avaliacao.id_avaliacao).length === 0) {

            _itemAvaliado.push({
                id_avaliacao: avaliacao.id_avaliacao,
                nota: nota,
                peso: avaliacao.peso
            });
        } else {
            var index = _itemAvaliado.findIndex((i) => i.id_avaliacao == avaliacao.id_avaliacao);
            _itemAvaliado[index].nota = nota;
        }

        var totalNota = 0;

        _itemAvaliado.map((a) => totalNota += (a.nota * a.peso));

        setNotaFinal(totalNota / pesoTotal);
        setItemAvaliado(itemAvaliado);

    }

    const getAvaliacoes = async () => {
        var nivel = 1;

        const avaliacoes = await avaliacaoService.avaliacoes(nivel);
       
        var _avaliacoes = avaliacoes.filter((x) => x.nivel === nivel);

        console.log(_avaliacoes)
        var pesoTotal = 0;
        _avaliacoes.map(function (a) {
            pesoTotal += parseInt(a.peso)
        });

        setPesoTotal(pesoTotal);
        setAvaliacoes(_avaliacoes);
    }

    const enviarAvaliacao = async () => {
        setLoad(true);
        var dataAvaliacao = [];

        avaliacoes.map((a) => {
            if (itemAvaliado.filter(i => i.id_avaliacao == a.id_avaliacao).length > 0) {

                var index = itemAvaliado.findIndex(i => i.id_avaliacao == a.id_avaliacao);

                dataAvaliacao.push({
                    id_avaliacao: a.id_avaliacao,
                    nota: itemAvaliado[index].nota,
                    avaliador: idUsuario
                });

            } else {
                dataAvaliacao.push({

                    id_avaliacao: a.id_avaliacao,
                    nota: 0,
                    avaliador: idUsuario
                });
            }
        });

        const response = await avaliacaoService.addAvaliacoesTrabalho(JSON.stringify({
            idTrabalho: trabalho.id,
            notaFinal: notaFinal,
            avaliacoes: dataAvaliacao
        }));

        if (response.status === 201) {
            setLoad(false);
            close();
        }

        setLoad(false)

    }
    return (<>

        <Dialog open={isOpen}
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="confirmation-dialog-title"
            maxWidth='md'
        >

            <DialogTitle children={`Avaliação`} onClose={close}></DialogTitle>
            <DialogContent dividers>

                <div style={{ backgroundColor: 'white', height: 350, width: 450 }}>
                    <FormGroup>
                        <fieldset>
                           
                            {
                                avaliacoes.map((a) => {

                                    return (<div class="titulo-range">
                                        <label>
                                            <Tooltip title={<div dangerouslySetInnerHTML={{ __html: a.info}}></div>} placement={'right'}>
                                                <b>{a.descricao}</b>
                                            </Tooltip>
                                        </label>
                                        <ReactStars
                                            count={5}
                                            min
                                            onChange={(nota) => avaliar(a, nota)}
                                            size={30}
                                            color2={'#ffd700'}
                                            value={itemAvaliado.filter((i) => i.id_avaliacao == a.id_avaliacao).length > 0 ? itemAvaliado.find((i) => i.id_avaliacao == a.id_avaliacao).nota : 0}
                                        />
                                      </div>)
                                })

                            }
                        </fieldset>
                        <div class="container-nota">
                            <span>Nota final</span>
                            <span>{notaFinal}</span>
                        </div>

                        <div style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center' }}>
                            {load ? <LoadComponent /> :
                                <button class="btn-enviar" onClick={() => enviarAvaliacao()}>Enviar</button>
                            }
                        </div>
                    </FormGroup>
                </div>


            </DialogContent>
        </Dialog>

    </>);

}
