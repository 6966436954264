import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CancelIcon from '@material-ui/icons/Cancel';

const styles = theme => ({
  root: {
 
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit / 2,
    top: theme.spacing.unit / 2,
    color: theme.palette.grey[500],
  },
})

function DialogTitle(props) {
  const { children, classes, onClose, TypographyProps } = props

  return (
    <div className={classes.root}>
      <div style={{padding: 15}}>
      <Typography align="right" variant="title" {...TypographyProps}>
        {children}
      </Typography>
      </div>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
           <CancelIcon style={{ color: '#3f5b70' }}  />
        </IconButton>
      ) : null}
    </div>
  )
}

DialogTitle.displayName = 'DialogTitle'

DialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  TypographyProps: PropTypes.object,
}

export default withStyles(styles)(DialogTitle)