import React, { useEffect, useState } from 'react';

export default function Footer() {


    return(
        <footer>
			<div class="rodape">
				<div class="rodape-ajuda">
					Tem mais alguma dúvida?<br/>
					Entre em contato pelo e-mail<br/>
					<a href="mailto:comunicacao@atvos.com" target="_blank">comunicacao@atvos.com</a><br></br>
					ou pelo <a href="https://api.whatsapp.com/send?phone=5511996562927" target="_blank">WhatsApp (11) 99656-2927</a>
				</div>
				<div class="rodape-logo">
					<img src={require('../assets/img/logo-rodape.png')}/>
				</div>
				<div class="rodape-social">
					<a href="https://www.facebook.com/somosatvos"><img src={require('../assets/img/logo-face.png')}/></a>
					<a href="https://twitter.com/SomosAtvos"><img src={require('../assets/img/logo-twitter.png')}/></a>
					<a href="https://instagram.com/somosatvos"><img src={require('../assets/img/logo-insta.png')}/></a>
					<a href="https://www.linkedin.com/company/11381362/"><img src={require('../assets/img/logo-link.png')}/></a>
					<a href="https://www.youtube.com/atvos"><img src={require('../assets/img/logo-you.png')}/></a>
				</div>
			</div>
			<div class="copyright">
				<a href="https://atvos.com">www.atvos.com</a>
			</div>
		</footer>
    );
}