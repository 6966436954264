import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { IconButton, TextField, DatePicker, Grid, styled, Paper } from '@material-ui/core';
import votoService from '../../services/voto/voto-service';
import avaliacaoService from '../../services/Avaliacao/avaliacao-service';
import ReactExport from 'react-export-excel';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function AvaliacaoAdmPage(props) {

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


 
    const [votos, setVotos] = useState([]);
    const [avaliacoes, setAvaliacoes] = useState([]);

    useEffect(() => {
        init();
    }, []);


    const init = async () => {

        await getAvaliacoes();

    }

    const getAvaliacoes = async () => {
        const response = await avaliacaoService.avaliacoesByNivel(1);
        setAvaliacoes(response);
    }


    return (<>
      

        <MaterialTable
            title={<div style={{ fontSize: '14px' }}>
                <div style={{ paddingRight: '50px' }}>
                    Avaliações
                    </div>

                <ExcelFile
                    filename='Avaliações'
                    element={<a style={{ cursor: 'pointer'}}><b>Exportar para Excel</b></a>}>
                    <ExcelSheet data={avaliacoes} name="Avaliações">
                        <ExcelColumn label="Titulo" value={(col) => col.titulo} />
                        <ExcelColumn label="Nome" value={(col) => col.descricao} />
                        <ExcelColumn label="Unidade" value={(col) => col.unidade} />
                        <ExcelColumn label="Area" value={(col) => col.area} />
                        <ExcelColumn label="Lider" value={(col) => col.lider} />
                        <ExcelColumn label="Categoria" value={(col) => col.categoria} />
                        <ExcelColumn label="Nivel" value={(col) => col.nivel} />
                        <ExcelColumn label="Criatividade e Inovação" value={(col) => col.criatividade_Inovacao} />
                        <ExcelColumn label="Resultados e retorno para a empresa" value={(col) => col.resultado_Retorno_Empresa} />
                        <ExcelColumn label="Aplicabilidade em outros ambientes" value={(col) => col.aplicalidade_Ambientes} />
                        <ExcelColumn label="Redação e qualidade do case" value={(col) => col.redacao_Qualidade} />
                        <ExcelColumn label="Nota Final" value={(col) => col.nota} />
                    </ExcelSheet>

                </ExcelFile>

            </div>}
            style={{ fontSize: '10px', fontWeight:'500px' }}
            columns={[
                { title: 'Titulo', field: 'titulo', editable: 'never' },
                { title: 'Nome', field: 'descricao', editable: 'never' },
                { title: 'Unidade', field: 'unidade' },
                { title: 'Area', field: 'area' },
                { title: 'Lider', field: 'lider' },
                { title: 'Categoria', field: 'categoria' },
                { title: 'Nivel', field: 'nivel' },
                { title: 'Avaliadores', field: 'avaliador' },
                { title: 'Criatividade e Inovação', field: 'criatividade_Inovacao' },
                { title: 'Resultados e retorno para a empresa', field: 'resultado_Retorno_Empresa' },
                { title: 'Aplicabilidade em outros ambientes', field: 'aplicalidade_Ambientes' },
                { title: 'Redação e qualidade do case', field: 'redacao_Qualidade' },
                { title: 'Nota Final', field: 'nota' },
            ]}
            data={avaliacoes}
        />

    </>);
}