
class TriagemService {




    async triagem(triagem) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/triagem`,
                {
                    method: 'POST',
                    body: JSON.stringify(triagem),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

    async detalhes(idTrabalho) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/triagem/detalhes/${idTrabalho}`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

}

export default new TriagemService();
