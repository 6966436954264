import { Dialog, DialogContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import '../../assets/styles/css/categorias.css';
import '../../assets/styles/css/inscrevase.css';
import '../../assets/styles/css/swiper-bundle.min.css';
import Alert from '../../components/Alert';
import DialogTitle from '../../components/DialogTitle';
import LoadComponent from '../../components/Load';

import categoriaService from '../../services/Categoria/categoria-service';
import configuracaoService from '../../services/Configuracao/configuracao-service';
import trabalhoService from '../../services/trabalho/trabalho-service';
import platform from 'platform';

export default function InscrevasePage(props) {

    const [categoriaSelecionada, setCategoriaSelecionada] = useState('PI');
    const [codCategorias, setCodCategorias] = useState([
        "PI",
        "CS",
        "SS",
        "IC",
        "PG"
    ]);
    const [categorias, setCategorias] = useState([]);
    const [indexCategoria, setIndexCategoria] = useState(0);
    const [inscricao, setInscricao] = useState({
        descricao: '',
        unidade: '',
        area: '',
        lider: '',
        titulo: '',
        idCategoria: 0,
    });
    const [valida, setValida] = useState(false);
    const [arquivo, setArquivo] = useState(null);
    const [usuario, setUsuario] = useState(null);
    const [alerta, setAlerta] = useState({
        show: false,
        mensagem: '',
        tipo: ''
    });
    const [load, setLoad] = useState(false);
    const [config, setConfiguracao] = useState(null);
    const [popUpTrabalho, setpopUpTrabalho] = useState(false);

    useEffect(() => {

        var _plataforma = platform.os.family;
        if (
            String(_plataforma).toLocaleLowerCase() === 'ios' ||
            String(_plataforma).toLocaleLowerCase() === 'android'
        ) {
            document.getElementById("navMenu").style.display = "none";
        }

      
        init()
    }, [])

   

    const init = async () => {
        var _usuario = await localStorage.getItem("usuario");
        if (!_usuario) {
            await localStorage.setItem('menu', 'login');
            props.history.push('/login');
           // setUsuario(_usuario);
        }
        setUsuario(JSON.parse(_usuario));
        console.log(JSON.parse(_usuario))
        await getCategorias();
        await getConfiguracao();
    }

   const onFileChange = event => {

       // Update the state
       console.log(event.target.files[0]);
       var tipo = event.target.files[0].name.split('.');
       console.log(tipo);
       if (tipo[1] === 'pdf' || tipo[1] === 'mp4' || tipo[1] === 'mov') {
           setArquivo(event.target.files[0]);
       } else {

           setArquivo(null);
           setAlerta({
               show: true,
               mensagem: 'Arquivo incompativel!',
               tipo: ''
           });
           return;

       }
       
    };

    const handleCategoriaSelecionada = (codCategoria) => {
        setCategoriaSelecionada(codCategoria);

        var index = codCategorias.findIndex(x => x === codCategoria);

        setIndexCategoria(index);
    }

    const getCategorias = async () => {
        const _categoriasResult = await categoriaService.categorias();

        console.log(_categoriasResult)
        setCategorias(_categoriasResult);
    }

    const sliderCategorias = (num) => {
        var resultado = indexCategoria + (num);
        console.log(resultado)
        if (resultado < 0) {
            setCategoriaSelecionada('PG');
            setIndexCategoria(4)
            return;
        } else if (resultado > 4) {
            setCategoriaSelecionada('PI');
            setIndexCategoria(0)
            return;
        }
        else {
            var _codCategoria = codCategorias[resultado];
            setCategoriaSelecionada(_codCategoria);
            setIndexCategoria(resultado)
            return;
        }
    }

    const validaInscricao = () => {
        if (!inscricao.descricao ||
            !inscricao.unidade ||
            !inscricao.area ||
            !inscricao.lider ||
            !inscricao.titulo
        ) {
            setValida(true);
            return true;
        }else{
            setValida(false);
            return false;
        }
    }

    const closePopUp = () => {
        setpopUpTrabalho(false);
    }

    const salvarTrabalho = async () => {
        if (!arquivo) {
            setAlerta({
                show: true,
                mensagem: 'Arquivo não anexado!',
                tipo: ''
            });
            return;
        }
        if (!validaInscricao()) {
            setLoad(true);

            var formdata = new FormData();
            const idCategoria = categorias.find(x => x.codigo === categoriaSelecionada).id_categoria;

            formdata.append("id_categoria", parseInt(idCategoria));
            formdata.append("titulo", inscricao.titulo);
            formdata.append("descricao", inscricao.descricao);
            formdata.append("unidade", inscricao.unidade);
            formdata.append("area", inscricao.area);
            formdata.append("lider", inscricao.lider);
            formdata.append("usuario", parseInt(usuario.id));
            formdata.append("arquivo", arquivo);

            const response = await trabalhoService.cadastrar(formdata);

            if (response.status === 201) {
                setpopUpTrabalho(true);
                setAlerta({
                    show: true,
                    mensagem: 'Trabalho cadastrado com sucesso!',
                    tipo: 'success'
                });
                limparCampos();
            }
            else
                setAlerta({
                    show: true,
                    mensagem: 'Não foi possivel cadastrar o trabalho',
                    tipo: ''
                });

            setLoad(false);
        }
    }

    const downloadExemplo = (codCategoria) => {
        switch (codCategoria) {
            case 'PI':
                return require('../../assets/documentos/Modelo_Destaque Atvos_Produtividade e Inovação.docx');
            case 'CS':
                return require('../../assets/documentos/Modelo_Destaque Atvos_Crescimento Sustentável.docx');
            case 'SS':
                return require('../../assets/documentos/Modelo_Destaque Atvos_Saúde e Segurança.docx');
            case 'IC':
                return require('../../assets/documentos/Modelo_Destaque Atvos_Iniciativas Corporativas.docx');
            case 'PG':
                return require('../../assets/documentos/Modelo_Destaque Atvos_Saúde e Segurança.docx');
            default:
                break;
        }

    
    }

    const limparCampos = () => {
        setInscricao({
            descricao: '',
            unidade: '',
            area: '',
            lider: '',
            titulo: '',

        });
        setArquivo(null);
    }

    const getConfiguracao = async () => {
        const config = await configuracaoService.configuracao('INS');
        setConfiguracao(config);
    }

    return (<>
        {
            popUpTrabalho ? <Dialog
                open={true}
                disableBackdropClick
                disableEscapeKeyDown
                aria-labelledby="confirmation-dialog-title"

            >
                <DialogTitle children={`Sucesso`} onClose={closePopUp}></DialogTitle>
                <DialogContent dividers>
                    <label>Trabalho cadastrado com sucesso!</label>
                </DialogContent>
            </Dialog> : null
        }
        {config && config?.ativo === true &&
            <section class="categorias">
            <h2 class="categorias-titulo">Selecione a categoria e inscreva-se!</h2>


                <div class="container-icons-diferenciais">

                    <div class="container-seta container-seta-direita" onClick={() => sliderCategorias(1)}></div>
                    <div class="container-seta container-seta-esquerda" onClick={() => sliderCategorias(-1)}></div>
                    <div class="seta seta-direita" onClick={() => sliderCategorias(1)}>
                        <img src="assets/img/seta-swiper.png" />
                    </div>
                    <div class="seta seta-esquerda" onClick={() => sliderCategorias(-1)}>
                        <img src="assets/img/seta-swiper.png" />
                    </div>

                    <div class="swiper-mobile swiper mySwiper">
                        <div class="swiper-wrapper">
                            {

                                indexCategoria == 0 ? (
                                    <div class="swiper-slide">
                                        <div class={categoriaSelecionada === 'PI' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                            <div class="moldura-icon">
                                                <img src={categoriaSelecionada === 'PI' ? require("../../assets/img/icones-categorias/icone-produtividade-active.png") : require("../../assets/img/icones-categorias/icone-produtividade.png")} alt="" />
                                            </div>
                                            <span>Produtividade e inovação</span>
                                        </div>
                                    </div>) : null
                            }
                            {
                                indexCategoria == 1 ? (<div class="swiper-slide">
                                    <div class={categoriaSelecionada === 'CS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                        <div class="moldura-icon">
                                            <img src={categoriaSelecionada === 'CS' ? require("../../assets/img/icones-categorias/icone-crescimento-active.png") : require("../../assets/img/icones-categorias/icone-crescimento.png")} alt="" />
                                        </div>
                                        <span>Crescimento sustentável</span>
                                    </div>
                                </div>) : null
                            }
                            {
                                indexCategoria == 2 ?
                                    (<div class="swiper-slide">
                                        <div class={categoriaSelecionada === 'SS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                            <div class="moldura-icon">
                                                <img src={categoriaSelecionada === 'SS' ? require("../../assets/img/icones-categorias/icone-saude-active.png") : require("../../assets/img/icones-categorias/icone-saude.png")} alt="" />
                                            </div>
                                            <span>Saúde e segurança</span>
                                        </div>
                                    </div>) : null
                            }
                            {
                                indexCategoria == 3 ?
                                    (<div class="swiper-slide">
                                        <div class={categoriaSelecionada === 'IC' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                            <div class="moldura-icon">
                                                <img src={categoriaSelecionada === 'IC' ? require("../../assets/img/icones-categorias/icone-iniciativas-active.png") : require("../../assets/img/icones-categorias/icone-iniciativas.png")} alt="" />
                                            </div>
                                            <span>Iniciativas corporativas</span>
                                        </div>
                                    </div>
                                    ) : null
                            }
                            {
                                indexCategoria == 4 ? (
                                    <div class="swiper-slide">
                                        <div class={categoriaSelecionada === 'PG' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                            <div class="moldura-icon">
                                                <img src={categoriaSelecionada === 'PG' ? require("../../assets/img/icones-categorias/icone-acoes-active.png") : require("../../assets/img/icones-categorias/icone-acoes.png")} alt="" />
                                            </div>
                                            <span>Pequenas ações<br />grandes feitos</span>
                                        </div>
                                    </div>) : null
                            }
                        </div>

                    </div>
                    <div class="container swiper-desktop swiper">
                        <div class={categoriaSelecionada === 'PI' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('PI')}>
                            <div class="moldura-icon">
                                <img src={categoriaSelecionada === 'PI' ? require("../../assets/img/icones-categorias/icone-produtividade-active.png") : require("../../assets/img/icones-categorias/icone-produtividade.png")} alt="" />
                            </div>
                            <span>Produtividade e inovação</span>
                        </div>
                        <div class={categoriaSelecionada === 'CS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('CS')}>
                            <div class="moldura-icon">
                                <img src={categoriaSelecionada === 'CS' ? require("../../assets/img/icones-categorias/icone-crescimento-active.png") : require("../../assets/img/icones-categorias/icone-crescimento.png")} alt="" />
                            </div>
                            <span>Crescimento sustentável</span>
                        </div>
                        <div class={categoriaSelecionada === 'SS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('SS')}>
                            <div class="moldura-icon">
                                <img src={categoriaSelecionada === 'SS' ? require("../../assets/img/icones-categorias/icone-saude-active.png") : require("../../assets/img/icones-categorias/icone-saude.png")} alt="" />
                            </div>
                            <span>Saúde e segurança</span>
                        </div>
                        <div class={categoriaSelecionada === 'IC' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('IC')}>
                            <div class="moldura-icon">
                                <img src={categoriaSelecionada === 'IC' ? require("../../assets/img/icones-categorias/icone-iniciativas-active.png") : require("../../assets/img/icones-categorias/icone-iniciativas.png")} alt="" />
                            </div>
                            <span>Iniciativas corporativas</span>
                        </div>
                        <div class={categoriaSelecionada === 'PG' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('PG')}>
                            <div class="moldura-icon">
                                <img src={categoriaSelecionada === 'PG' ? require("../../assets/img/icones-categorias/icone-acoes-active.png") : require("../../assets/img/icones-categorias/icone-acoes.png")} alt="" />
                            </div>
                            <span>Pequenas ações grandes feitos</span>
                        </div>
                    </div>

                </div>

                <img class={"categoria-selecionado-" + categoriaSelecionada} src="assets/img/categoria-selecionado.png" />

                <div class="container container-inscrevase">
                    <div class="container-informacoes">
                        <article class="chamada-informacoes">
                            {categoriaSelecionada === 'PG' ? <>
                                <h3>Grave um vídeo com até 3 minutos explicando seu trabalho:</h3>
                                <ul style={{
                                    listStyleType: 'circle', paddingLeft: '15px'
                                }}>
                                    <li>O que é?</li>
                                    <li>Como foi realizado?</li>
                                    <li>Quais são as equipes envolvidas?</li>
                                    <li>Quais foram os resultados ou benefícios gerados?</li>
                                    <li>Outros detalhes que considerar importantes.</li>
                                </ul>

                            </> : <>
                                <h3>Baixe aqui o arquivo para inscrever o seu trabalho:</h3>
                                <a href={downloadExemplo(categoriaSelecionada)}>
                                    <button>Clique aqui</button>
                                </a>
                            </>
                            }
                        </article>
                        <article class="chamada-informacoes">
                            <h3>Lembre-se de conferir o regulamento:</h3>
                            <a href={'/regulamentos'}>
                                <button>Confira aqui</button>
                            </a>
                        </article>
                    </div>

                    <div class="container-form">

                        <h3 class="titulo-form">Preencha <br /> as informações abaixo</h3>

                        <label for="descricao">Nome Completo:</label>
                        {valida && !inscricao.descricao ? <><br /><label style={{ color: 'red' }}>Preencha o campo Descrição</label><br /></> : null}
                        <input id="descricao" value={inscricao.descricao} type="text" name="descricao" onChange={(e) => setInscricao({ ...inscricao, descricao: e.target.value })} />
                        <label for="unidade">Unidade:</label>
                        {valida && !inscricao.unidade ? <><br /><label style={{ color: 'red' }}>Preencha o campo Unidade</label><br /></> : null}
                        <select value={inscricao.unidade} name="unidade" id="unidade" onChange={(e) => setInscricao({ ...inscricao, unidade: e.target.value })}>
                            <option value="">Selecione a Unidade</option>
                            {categoriaSelecionada === 'IC' && <>
                                <option value="Escritório São Paulo">Escritório São Paulo</option>
                                <option value="Escritório Campinas">Escritório Campinas</option>
                            </>}
                            {categoriaSelecionada !== 'IC' && <>
                                <option value="Alto Taquari">Alto Taquari</option>
                                <option value="Água Emendada">Água Emendada</option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Conquista do Pontal">Conquista do Pontal</option>
                            </>
                            }
                            {['PI', 'CS', 'SS', 'PG'].filter(x => x === categoriaSelecionada).length > 0 &&
                                <>

                                    <option value="Alcídia">Alcídia</option>
                                </>
                            }
                            {['PI', 'CS', 'SS', 'PG', 'IC'].filter(x => x === categoriaSelecionada).length == 0 &&
                                <>
                                    <option value="Corporativo">Corporativo</option>
                                </>
                            }
                            {categoriaSelecionada !== 'IC' && <>
                                <option value="Eldorado">Eldorado</option>
                                <option value="Morro Vermelho">Morro Vermelho</option>
                                <option value="Rio Claro">Rio Claro</option>
                                <option value="Santa Luzia">Santa Luzia</option>
                            </>
                            }
                        </select>
                        {/*<input id="unidade" value={inscricao.unidade} name="unidade" type="text" onChange={(e) => setInscricao({ ...inscricao, unidade: e.target.value })} />*/}
                        <label for="area">Área:</label>
                        {valida && !inscricao.area ? <><br /><label style={{ color: 'red' }}>Preencha o campo Área</label><br /></> : null}
                        {/*<input id="area" value={inscricao.area} name="area" type="text" onChange={(e) => setInscricao({ ...inscricao, area: e.target.value })} />*/}
                        <select value={inscricao.area} name="area" id="area" onChange={(e) => setInscricao({ ...inscricao, area: e.target.value })}>
                            <option value="">Selecione a Área</option>
                            <option value="Comercial">Comercial</option>
                            <option value="Energia">Energia</option>
                            <option value="Logística">Logística</option>
                            <option value="Jurídico e Governança">Jurídico e Governança</option>
                            <option value="Finanças e Controladoria">Finanças e Controladoria</option>
                            <option value="TI">TI</option>
                            <option value="P&O">P&O</option>
                            <option value="Serviços Administrativos">Serviços Administrativos</option>
                            <option value="Relações Institucionais e Governamentais">Relações Institucionais e Governamentais</option>
                            <option value="Comunicação">Comunicação</option>
                            <option value="Sustentabilidade">Sustentabilidade</option>
                            <option value="Riscos e Conformidade">Riscos e Conformidade</option>
                            <option value="Manutenção">Manutenção</option>
                            <option value="Operações Agrícolas">Operações Agrícolas</option>
                            <option value="Parcerias">Parcerias</option>
                            <option value="Tecnologia Agrícola">Tecnologia Agrícola</option>
                            <option value="Transformação, Processos e Custos">Transformação, Processos e Custos</option>
                            <option value="Suprimentos">Suprimentos</option>
                            <option value="SSMA">SSMA</option>
                            <option value="Indústria">Indústria</option>

                        </select>

                        <label for="lider">Nome do seu/sua líder direto(a):</label>
                        {valida && !inscricao.lider ? <><br /><label style={{ color: 'red' }}>Preencha o campo Lider</label><br /></> : null}
                        <input id="lider" value={inscricao.lider} name="lider" type="text" onChange={(e) => setInscricao({ ...inscricao, lider: e.target.value })} />
                        <label for="titulo">Título do trabalho: </label>
                        {valida && !inscricao.titulo ? <><br /><label style={{ color: 'red' }}>Preencha o campo Titulo</label><br /></> : null}
                        <input id="titulo" value={inscricao.titulo} name="titulo" type="text" onChange={(e) => setInscricao({ ...inscricao, titulo: e.target.value })} />
                        <label for="arquivo">{categoriaSelecionada !== "PG" ? "Anexe o arquivo-modelo preenchido (Apenas PDF)" : "Anexe o vídeo (Apenas mp4)"}: <div style={{ color: '#ff681a' }}>{arquivo?.name}</div></label>
                        <div class="container-botoes">
                            <div class="botao-upload-file">
                                <div style={{ color: '#2A5B71' }}>{categoriaSelecionada !== "PG" ? "Anexar arquivo" : "Anexar vídeo"}</div>
                                <input
                                    id="arquivo"
                                    name="arquivo"
                                    type="file"
                                    onChange={onFileChange}
                                    accept={categoriaSelecionada !== "PG" ? "application/pdf" : "video/mp4"}
                                />
                            </div>
                            {load ? <center><LoadComponent /></center> : <button class="botao-enviar" onClick={() => salvarTrabalho()}>Enviar</button>}
                        </div>

                        {
                            alerta.show ? <><br /><Alert mensagem={alerta.mensagem} tipo={alerta.tipo} style={{ width: '95%' }} /></> : null
                        }


                    </div>
                </div>


            </section>
        }    {
            config && config?.ativo === false &&
            <center>
                <br />
                <br />
                <br />
                <h1>
                    { /*Em breve você poderá registrar o seu trabalho.*/}
                    As inscrições para a edição 2022 estão encerradas.
                </h1>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </center>
        }
    </>);
}