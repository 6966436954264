import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import UsuarioPage from '../usuario/usuario-page';
import UsuarioAdmPage from './usuarios-adm-page';
import ConfigPage from './config-page';
import VotoAdmPage from './voto-page';
import AvaliacaoAdmPage from './avaliacao-adm-page';
import { useState } from 'react';
import { useEffect } from 'react';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
});

export default function AdmPage(props){

    const [value, setValue] = useState(0)


    useEffect(() => {

        init();

    }, []);

    const init = async () => {
        var usuario = await localStorage.getItem("usuario");
        console.log(usuario)
        if (!usuario) {
            await localStorage.setItem('menu', 'login');
            props.history.push('/login')
        } else {
            var user = JSON.parse(usuario);
            if (user.perfis.filter(x => x.perfil === 'ADM').length == 0) {
                await localStorage.setItem('menu', 'login');
                props.history.push('/login');
            }
        }
    }
    
    const handleChange = (event, value) => {
        setValue(value)
    };
    
    
        return (
            <div>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Usuários" />
                        <Tab label="Configurações" />
                        <Tab label="Votos" />
                        <Tab label="Avaliações" />
 
                    </Tabs>
                </AppBar>
                {value === 0 && <TabContainer><UsuarioAdmPage/></TabContainer>}
                {value === 1 && <TabContainer><ConfigPage/></TabContainer>}
                {value === 2 && <TabContainer><VotoAdmPage/></TabContainer>}
                {value === 3 && <TabContainer><AvaliacaoAdmPage/></TabContainer>}
                {value === 4 && <TabContainer>Item Five</TabContainer>}
                {value === 5 && <TabContainer>Item Six</TabContainer>}
                {value === 6 && <TabContainer>Item Seven</TabContainer>}
            </div>
        );
 }