import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Checkbox, Dialog, DialogContent, FormControlLabel, FormGroup } from '@material-ui/core';
import DialogTitle from '../../../components/DialogTitle';
import usuarioService from '../../../services/usuario/usuario-service';

export default function UsuarioPerfilComponent({ idUsuario, isOpen, close }) {

    const [perfis, setPerfis] = useState([]);

    useEffect(()=>{
        if(isOpen)
            init();

    }, [isOpen]); 
    
    const init = async()=> {
        await getPerfis();
    }

    const getPerfis = async()=>{
        const response = await usuarioService.perfis(idUsuario);

        if(response.status === 200){
            var data = await response.json();
            setPerfis(data);
        }
    }
    const addPerfil = async (perfil) => {

        if (perfis.filter(x => x.perfil === perfil).length == 0) {
            var dataPerfil = JSON.stringify({
                idUsuario: idUsuario,
                perfil: perfil
            });

            const response = await usuarioService.addPerfil(dataPerfil);

            if (response.status === 201) {
                await getPerfis();
            }
        } else {
            const response = await usuarioService.removePerfil(idUsuario, perfil);

            if (response.status === 204) {
                await getPerfis();
            }
        }
    }

    return (<>

        <Dialog open={isOpen}
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="confirmation-dialog-title"
            maxWidth='md'
        >

            <DialogTitle children={`Perfis`} onClose={close}></DialogTitle>
            <DialogContent dividers>

                <div style={{ backgroundColor: 'white', height: 200, width: 450 }}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={perfis.filter(x => x.perfil === 'ADM').length > 0} onChange={() => addPerfil('ADM')} color='#3f5b70' />} label="Adm" />
                        <FormControlLabel control={<Checkbox checked={perfis.filter(x => x.perfil === 'TRI').length > 0} onChange={() => addPerfil('TRI')} color='#3f5b70' />} label="Triagem" />
                        <FormControlLabel control={<Checkbox checked={perfis.filter(x => x.perfil === 'AV1').length > 0} onChange={() => addPerfil('AV1')} color='#3f5b70' />} label="Avaliador 1" />
                        <FormControlLabel control={<Checkbox checked={perfis.filter(x => x.perfil === 'AV2').length > 0} onChange={() => addPerfil('AV2')} color='#3f5b70' />} label="Avaliador 2" />
                    </FormGroup>
                </div>


            </DialogContent>
        </Dialog>

    </>);

}
