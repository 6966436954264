import React, { useEffect, useState } from 'react';
import '../../assets/styles/css/categorias.css';
import '../../assets/styles/css/votacao.css';
import '../../assets/styles/css/espaco-avaliador.css';
import '../../assets/styles/css/swiper-bundle.min.css';
import '../../assets/styles/css/pop-up.css';

import categoriaService from '../../services/Categoria/categoria-service';
import trabalhoService from '../../services/trabalho/trabalho-service';
import LoadComponent from '../../components/Load';
import triagemService from '../../services/triagem/triagem-service';
import StatusUtil from '../../Util/status-util';
import votoService from '../../services/voto/voto-service';
import configuracaoService from '../../services/Configuracao/configuracao-service';
import { Dialog, DialogContent } from '@material-ui/core';
import DialogTitle from '../../components/DialogTitle';
import platform from 'platform';


export default function VotacaoPage(props) {

    const [categoriaSelecionada, setCategoriaSelecionada] = useState('PI');
    const [codCategorias, setCodCategorias] = useState([
        "PI",
        "CS",
        "SS",
        "IC",
        "PG"
    ]);
    const [categorias, setCategorias] = useState([]);
    const [indexCategoria, setIndexCategoria] = useState(0);
    const [trabalhos, setTrabalhos] = useState([]);
    const [load, setLoad] = useState(false);
    const [triagem, setTriagem] = useState({
        aprovado: false,
        comentario: ''
    });
    const [popUpTriagem, setPopUpTriagem] = useState(false);
    const [trabalho, setTrabalho] = useState(null);
    const [usuario, setUsuario] = useState(null);
    const [loadTriagem, setLoadTriagem] = useState(false);
    const [popUpDetalhes, setPopUpDetalhes] = useState(false);
    const [dialogAvaliacao, setdialogAvaliacao] = useState(false);
    const [votos, setVotos] = useState([]);
    const [config, setConfiguracao] = useState(null);
    const [popUpVotacao, setPopUpVotacao] = useState(false);

    useEffect(() => {
        var _plataforma = platform.os.family;
        if (
            String(_plataforma).toLocaleLowerCase() === 'ios' ||
            String(_plataforma).toLocaleLowerCase() === 'android'
        ) {
            document.getElementById("navMenu").style.display = "none";
        }

        init()

    }, []);

    const init = async () => {

        var _usuario = await localStorage.getItem("usuario");
        console.log(_usuario)
        if (!_usuario) {
            await localStorage.setItem('menu', 'login');
            props.history.push('/login');
        }
        var user = JSON.parse(_usuario)
        setUsuario(user);
        if (user) {
            await localStorage.setItem('menu', 'categoria');
            await votosUsuario(user.id);
            await getCategorias();
            await getTrabalhos();
            await getConfiguracao();
        }
    }

    const getConfiguracao = async () => {
        const config = await configuracaoService.configuracao('VOT');

        setConfiguracao(config);
    }
    const votosUsuario = async (idUsuario) => {
        const _votos = await votoService.votoByUsuario(idUsuario);

        if (_votos.status === 200) {
            var data = await _votos.json();
            setVotos(data);
        }
    }

    const handleopenDialogAvaliacao = (trabalho) => {
        setTrabalho(trabalho);
        setdialogAvaliacao(true);
    }

    const handleCloseDialogAvaliacao = async () => {
        setTrabalho(null);
        setdialogAvaliacao(false);
        getTrabalhos();
    }
    const handleCategoriaSelecionada = (codCategoria) => {
        setCategoriaSelecionada(codCategoria);

        var index = codCategorias.findIndex(x => x === codCategoria);

        setIndexCategoria(index);
    }

    const getCategorias = async () => {
        const _categoriasResult = await categoriaService.categorias();

        console.log(_categoriasResult)
        setCategorias(_categoriasResult);
    }

    const getTrabalhos = async () => {
        setLoad(true);
        const _trabalhos = await trabalhoService.trabalhosByStatus('VT');
        setTrabalhos(_trabalhos);
        setLoad(false);

    }

    const sliderCategorias = (num) => {
        var resultado = indexCategoria + (num);
        console.log(resultado)
        if (resultado < 0) {
            setCategoriaSelecionada('PG');
            setIndexCategoria(4)
            return;
        } else if (resultado > 4) {
            setCategoriaSelecionada('PI');
            setIndexCategoria(0)
            return;
        }
        else {
            var _codCategoria = codCategorias[resultado];
            setCategoriaSelecionada(_codCategoria);
            setIndexCategoria(resultado)
            return;
        }
    }

    const salvarVoto = async (_trabalho) => {
        setLoadTriagem(true)
        const dataVoto = {
            id_trabalho: _trabalho.id,
            id_usuario: parseInt(usuario.id)
        }
        const cadastrado = await votoService.voto(dataVoto);

        if (cadastrado.status === 201) {
            await votosUsuario(usuario.id);
            await getTrabalhos();
            setPopUpVotacao(true);
           // closeTriagem();
        }

    }

    const openDetalhes = (trabalho) => {
        setTrabalho(trabalho);
        setPopUpDetalhes(true);
    }

    const closeDetalhes = (trabalho) => {
        setTrabalho(null);
        setPopUpDetalhes(false);
    }

    const closePopUp = () => {
        setPopUpVotacao(false);
    }
    return (<>
        {
            popUpVotacao ? <Dialog open={true}
                disableBackdropClick
                disableEscapeKeyDown
                aria-labelledby="confirmation-dialog-title"

            >

                <DialogTitle children={`Sucesso`} onClose={closePopUp}></DialogTitle>
                <DialogContent dividers>


                    <label>Voto efetuado com sucesso</label>


                </DialogContent>
            </Dialog> : null
        }
        {popUpDetalhes ?
            <article class="pop-up">

                Detalhes

                <div style={{ overflowY: 'auto', padding: '12px', height: '200px', width: '100%' }}>
                    <label>Descrição: {trabalho?.descricao} </label><br />
                    <label>Unidade: {trabalho?.unidade} </label><br />
                    <label>Área: {trabalho?.area} </label><br />
                    <label>Líder: {trabalho?.lider} </label><br />
                    <label>Título do trabalho: {trabalho?.titulo}</label><br />
                    <label>Status: {StatusUtil.getSatatus(trabalho?.status)}</label><br />

                </div>



                <div style={{ float: 'right', paddingRight: '10px' }}>
                    <input type="submit" value="Fechar" class="trabalho-item-btn" onClick={() => closeDetalhes()}></input>
                </div>

            </article> : null
        }
        {config && config?.ativo === true &&
            <section class="categorias">
                <h2 class="categorias-titulo">Selecione a Categoria</h2>


                <div class="container-icons-diferenciais">

                    <div class="container-seta container-seta-direita" onClick={() => sliderCategorias(1)}></div>
                    <div class="container-seta container-seta-esquerda" onClick={() => sliderCategorias(-1)}></div>
                    <div class="seta seta-direita" onClick={() => sliderCategorias(1)}>
                        <img src="assets/img/seta-swiper.png" />
                    </div>
                    <div class="seta seta-esquerda" onClick={() => sliderCategorias(-1)}>
                        <img src="assets/img/seta-swiper.png" />
                    </div>

                    <div class="swiper-mobile swiper mySwiper">
                        <div class="swiper-wrapper">
                            {

                                indexCategoria == 0 ? (
                                    <div class="swiper-slide">
                                        <div class={categoriaSelecionada === 'PI' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                            <div class="moldura-icon">
                                                <img src={categoriaSelecionada === 'PI' ? require("../../assets/img/icones-categorias/icone-produtividade-active.png") : require("../../assets/img/icones-categorias/icone-produtividade.png")} alt="" />
                                            </div>
                                            <span>Produtividade e inovação</span>
                                        </div>
                                    </div>) : null
                            }
                            {
                                indexCategoria == 1 ? (<div class="swiper-slide">
                                    <div class={categoriaSelecionada === 'CS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                        <div class="moldura-icon">
                                            <img src={categoriaSelecionada === 'CS' ? require("../../assets/img/icones-categorias/icone-crescimento-active.png") : require("../../assets/img/icones-categorias/icone-crescimento.png")} alt="" />
                                        </div>
                                        <span>Crescimento sustentável</span>
                                    </div>
                                </div>) : null
                            }
                            {
                                indexCategoria == 2 ?
                                    (<div class="swiper-slide">
                                        <div class={categoriaSelecionada === 'SS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                            <div class="moldura-icon">
                                                <img src={categoriaSelecionada === 'SS' ? require("../../assets/img/icones-categorias/icone-saude-active.png") : require("../../assets/img/icones-categorias/icone-saude.png")} alt="" />
                                            </div>
                                            <span>Saúde e segurança</span>
                                        </div>
                                    </div>) : null
                            }
                            {
                                indexCategoria == 3 ?
                                    (<div class="swiper-slide">
                                        <div class="icon-diferenciais">
                                            <div class={categoriaSelecionada === 'IC' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                                <img src={categoriaSelecionada === 'IC' ? require("../../assets/img/icones-categorias/icone-iniciativas-active.png") : require("../../assets/img/icones-categorias/icone-iniciativas.png")} alt="" />
                                            </div>
                                            <span>Iniciativas corporativas</span>
                                        </div>
                                    </div>
                                    ) : null
                            }
                            {
                                indexCategoria == 4 ? (
                                    <div class="swiper-slide">
                                        <div lass={categoriaSelecionada === 'PG' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                            <div class="moldura-icon">
                                                <img src={categoriaSelecionada === 'PG' ? require("../../assets/img/icones-categorias/icone-acoes-active.png") : require("../../assets/img/icones-categorias/icone-acoes.png")} alt="" />
                                            </div>
                                            <span style={{ width: '150%' }}>Pequenas ações grandes feitos</span>
                                        </div>
                                    </div>) : null
                            }
                        </div>

                    </div>

                    <div class="container swiper-desktop swiper">
                        <div class={categoriaSelecionada === 'PI' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('PI')}>
                            <div class="moldura-icon">
                                <img src={categoriaSelecionada === 'PI' ? require("../../assets/img/icones-categorias/icone-produtividade-active.png") : require("../../assets/img/icones-categorias/icone-produtividade.png")} alt="" />
                            </div>
                            <span>Produtividade e inovação</span>
                        </div>
                        <div class={categoriaSelecionada === 'CS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('CS')}>
                            <div class="moldura-icon">
                                <img src={categoriaSelecionada === 'CS' ? require("../../assets/img/icones-categorias/icone-crescimento-active.png") : require("../../assets/img/icones-categorias/icone-crescimento.png")} alt="" />
                            </div>
                            <span>Crescimento sustentável</span>
                        </div>
                        <div class={categoriaSelecionada === 'SS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('SS')}>
                            <div class="moldura-icon">
                                <img src={categoriaSelecionada === 'SS' ? require("../../assets/img/icones-categorias/icone-saude-active.png") : require("../../assets/img/icones-categorias/icone-saude.png")} alt="" />
                            </div>
                            <span>Saúde e segurança</span>
                        </div>
                        <div class={categoriaSelecionada === 'IC' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('IC')}>
                            <div class="moldura-icon">
                                <img src={categoriaSelecionada === 'IC' ? require("../../assets/img/icones-categorias/icone-iniciativas-active.png") : require("../../assets/img/icones-categorias/icone-iniciativas.png")} alt="" />
                            </div>
                            <span>Iniciativas corporativas</span>
                        </div>
                        <div class={categoriaSelecionada === 'PG' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('PG')}>
                            <div class="moldura-icon">
                                <img src={categoriaSelecionada === 'PG' ? require("../../assets/img/icones-categorias/icone-acoes-active.png") : require("../../assets/img/icones-categorias/icone-acoes.png")} alt="" />
                            </div>
                            <span>Pequenas ações grandes feitos</span>
                        </div>
                    </div>
                </div>

                <img class={"categoria-selecionado-" + categoriaSelecionada} src="assets/img/categoria-selecionado.png" />

                {load ? <div class="container container-categoria">
                    <div style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}><LoadComponent /></div>
                </div> :

                    <div class="trabalhos">
                        {trabalhos.filter(x => x.categoria.cod === categoriaSelecionada).map((tr) =>
                            <div class="trabalho-item">
                                <div class="trabalho-item-titulo">
                                    {tr.titulo}
                                </div>
                                <div class="trabalho-item-titulo">
                                    {tr.autor.nome}
                                </div>
                                <div class="trabalho-item-titulo">
                                    Status: {StatusUtil.getSatatus(tr.status)}
                                </div>

                                <div class="trabalho-item-btn" onClick={() => openDetalhes(tr)}>
                                    Detalhes
                                </div>

                                <a href={`api/anexos/${tr.id}`} download>
                                    <div class="trabalho-item-btn">
                                        {categoriaSelecionada === "PG" ? "Baixar Video" : "Baixar PDF"}
                                    </div>
                                </a>
                                {votos.filter(x => x.categoria.cod === categoriaSelecionada).length === 0 ?
                                    <div onClick={() => salvarVoto(tr)} class="trabalho-item-btn trabalho-item-btn-avaliar">
                                        Votar
                                    </div>
                                    : null
                                }
                            </div>
                        )
                        }


                    </div>
                }

            </section>

        }
        {
            config && config?.ativo === false  &&
            <center>
                <br />
                <br />
                <br />
                <h1>
                    Em breve você poderá votar no seu trabalho favorito.
                </h1>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </center>
        }
        
    </>);
}