class VotoService {

    async voto(voto) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/Voto`,
                {
                    method: 'POST',
                    body: JSON.stringify(voto),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

    async votos() {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/Voto`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

    async votoByUsuario(idUsuario) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/Voto/usuario/${idUsuario}`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

}
export default new VotoService();