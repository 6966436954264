import React, { useEffect, useState } from 'react';

export default function TermoComponent() {



    return (
        <>
            <b>TERMO DE ADESÃO E PARTICIPAÇÃO NO PRÊMIO DESTAQUE ATVOS 2022 COM CESSÃO DE DIREITOS AUTORAIS E AUTORIZAÇÃO DE USO DE IMAGEM </b><br /><br />

            <p>Eu, na qualidade de detentor dos direitos autorais patrimoniais sobre o projeto inscrito no Prêmio Destaque Atvos 2022 e titular do direito de personalidade sobre minha imagem, som da voz e nome em questão, doravante denominado “CEDENTE/AUTORIZANTE”, na melhor forma do direito, de maneira livre, espontânea, sem qualquer vício de consentimento ou de vontade, venho firmar o presente Termo com Atvos Agroindustrial Participações S/A. – Em recuperação judicial, inscrita no CNPJ sob o nº 08.842.690/0001-38, doravante denominada “ATVOS”, de acordo com as seguintes condições:</p><br /><br />

            <p>1. O CEDENTE/AUTORIZANTE aceita fornecer somente os dados pessoais como NOME, CPF e E-MAIL para cadastro no site do Prêmio Destaque Atvos 2022 e sua identificação enquanto a premiação estiver vigente, pelo período de cinco meses até 30 de junho de 2022. Os dados fornecidos serão cruzados com a base de dados da ATVOS para identificação dos integrantes. Posteriormente ao período da premiação, os dados pessoais serão excluídos da base e apenas as Obras mantidas em um acervo para consulta.</p><br /><br />

            <p>2. A ATVOS coletará os dados de NOME, CPF e E-MAIL única e exclusivamente para identificar os integrantes da empresa, validando por meio de cruzamento com a base de dados People Soft, garantindo que apenas estes recebam a senha de acesso no E-MAIL informado.</p><br /><br />

            <p>3. O CEDENTE/AUTORIZANTE transfere (CESSÃO) à ATVOS os direitos patrimoniais autorais sobre o projeto inscrito no Prêmio Destaque Atvos 2022 de forma gratuita, integral, irrestrita, irrevogável, irretratável e com abrangência universal, incluindo na internet, estando a Atvos autorizada a explorar a Obra da maneira que melhor lhe aprouver, podendo fazê-lo para fins comerciais, publicitários, institucionais, históricos, jornalísticos e educacionais, sendo permitido, ainda:</p><br /><br />

            <p>a) Transferir, migrar, deslocar, alterar, adaptar, traduzir ou, de qualquer forma, mudar o formato ou extensão do suporte ao qual a Obra tenha sido capturada, com a finalidade de permitir sua exposição em determinado material, viabilizando o uso por integrantes e terceiros;</p><br /><br />

            <p> b) Transmitir a Obra e/ou o seu suporte através de qualquer meio, seja eletrônico, digital, magnético, fibra ótica, ou qualquer outro que venha a ser inventado;</p><br /><br />

            <p> c) Armazenar, agrupar ou, de qualquer forma, organizar a Obra e/ou o seu suporte, seja em banco de dados, servidores internos, externos, de maneira integral ou fracionada;</p><br /><br />

            <p>d) Veicular ou distribuir a Obra dentro de seu ambiente físico e digital, em formato físico ou pela Internet, podendo ser disponibilizada nas mídias sociais, aplicativos, e-mail, sites de compartilhamento de imagens ou vídeos, por intermédio de computadores pessoais, celulares, smartphones, tablets, laptops ou qualquer outro dispositivo que possa reproduzir, armazenar, compartilhar, editar ou receber a Obra;</p><br /><br />

            <p> e) Reproduzir ou autorizar que os integrantes da ATVOS reproduzam a Obra, no todo ou em parte, incluindo, mas não se limitando, em publicações impressas, eletrônicas, digitais e/ou em composição multimídia, sem limite de suporte ou modalidade de uso, tiragem, tempo e distribuição, bem como em peças promocionais de qualquer natureza, a serem divulgadas nos diversos veículos de mídia utilizados.</p><br /><br />

            <p>4. O CEDENTE/AUTORIZANTE declara que é o detentor dos direitos patrimoniais relacionados com a Obra e que o uso desta não fere direitos de terceiros, sob pena de responder pela integralidade dos danos eventualmente causados.</p><br /><br />

            <p>5. O CEDENTE/AUTORIZANTE declara-se ciente e plenamente de acordo que as Obras não podem ser compartilhadas para terceiros, fornecedores, concorrentes ou para fora do país sem autorização dos organizadores do Prêmio Destaque Atvos.</p><br /><br />

            <p>6. A ATVOS não se responsabilizará pelo uso das Obras para propósito distinto do acordado.</p><br /><br />

            <p>7. O CEDENTE/AUTORIZANTE assente na utilização de sua imagem, som da voz e/ou nome em caráter definitivo, inequívoco, irrestrito, irretratável e de abrangência global, incluindo meios tradicionais de mídia e publicidade, internet e redes sociais, para fins específicos de divulgação e compartilhamento do conteúdo gerado no Prêmio Destaque Atvos 2022, enquanto durar a relação de prestação de serviços com a ATVOS ou independentemente de prazo para acervo histórico.</p><br /><br />

            <p>8. O CEDENTE/AUTORIZANTE declara e reconhece que a ATVOS fica autorizada a ceder ou transferir os direitos e obrigações oriundos do presente Termo a qualquer tempo para empresas do mesmo grupo econômico, afiliadas, controlados ou coligadas em função de reestruturação societária, fusão, cisão ou incorporação.</p><br /><br />

            <p>9. O presente Termo não gera qualquer dever de citação do nome do CEDENTE/AUTORIZANTE nos conteúdos gerados com a sua participação.</p><br /><br />

            <p> 10. O presente Termo obriga as Partes e seus sucessores a qualquer título.</p><br /><br />

            <p>11. CEDENTES/AUTORIZANTES e ATVOS elegem o foro da Comarca de São Paulo, Estado de São Paulo para dirimir quaisquer questões oriundas ao presente Termo.</p><br /><br />

            <p>12. O CEDENTE/AUTORIZANTE pode exercer seus direitos quanto a consulta do tratamento de seus dados e pedido de exclusão e suspensão de uso por meio de manifesto em canais oficiais.</p><br /><br />

            <p>13. O CEDENTE/AUTORIZANTE, ao clicar no item “Li e aceito o Termo de Adesão e Participação”, entende e concorda com todas as condições apresentadas acima no documento.</p><br /><br />
        </>
    )
}