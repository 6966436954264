import React, { useEffect, useState } from 'react';
import '../../assets/styles/css/categorias.css';
import '../../assets/styles/css/votacao.css';
import '../../assets/styles/css/espaco-avaliador.css';
import '../../assets/styles/css/swiper-bundle.min.css';
import '../../assets/styles/css/pop-up.css';

import categoriaService from '../../services/Categoria/categoria-service';
import trabalhoService from '../../services/trabalho/trabalho-service';
import LoadComponent from '../../components/Load';
import triagemService from '../../services/triagem/triagem-service';
import statusUtil from '../../Util/status-util';
import { Avatar, Dialog, DialogContent, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import DialogTitle from '../../components/DialogTitle';
import platform from 'platform';


export default function UsuarioPage(props) {

    const [categoriaSelecionada, setCategoriaSelecionada] = useState('PI');
    const [codCategorias, setCodCategorias] = useState([
        "PI",
        "CS",
        "SS",
        "IC",
        "PG"
    ]);
    const [categorias, setCategorias] = useState([]);
    const [indexCategoria, setIndexCategoria] = useState(0);
    const [trabalhos, setTrabalhos] = useState([]);
    const [load, setLoad] = useState(false);
    const [triagem, setTriagem] = useState({
        aprovado: false,
        comentario: ''
    });
    const [popUpTriagem, setPopUpTriagem] = useState(false);
    const [trabalho, setTrabalho] = useState(null);
    const [usuario, setUsuario] = useState(null);
    const [loadTriagem, setLoadTriagem] = useState(false);
    const [popUpDetalhes, setPopUpDetalhes] = useState(false);
    const [arquivo, setArquivo] = useState(null);
    const [comentarios, setComentarios] = useState([]);

    useEffect(() => {
        var _plataforma = platform.os.family;
        if (
            String(_plataforma).toLocaleLowerCase() === 'ios' ||
            String(_plataforma).toLocaleLowerCase() === 'android'
        ) {
            document.getElementById("navMenu").style.display = "none";
        }

        init()

    }, []);

    const init = async () => {

        var _usuario = await localStorage.getItem("usuario");
        console.log(_usuario)
        if (!_usuario) {
            await localStorage.setItem('menu', 'login');
            props.history.push('/login');
        }
        setUsuario(JSON.parse(_usuario));
        var user = JSON.parse(_usuario);
        await localStorage.setItem('menu', 'categoria');
        await getCategorias();
        await getTrabalhos(user.id);
    }

    const handleCategoriaSelecionada = (codCategoria) => {
        setCategoriaSelecionada(codCategoria);

        var index = codCategorias.findIndex(x => x === codCategoria);

        setIndexCategoria(index);
    }

    const getCategorias = async () => {
        const _categoriasResult = await categoriaService.categorias();

        console.log(_categoriasResult)
        setCategorias(_categoriasResult);
    }

    const getTrabalhos = async (id) => {
        setLoad(true);
        const _trabalhos = await trabalhoService.trabalhosByUsuario(id);
        setTrabalhos(_trabalhos);
        setLoad(false);

    }

    const sliderCategorias = (num) => {
        var resultado = indexCategoria + (num);
        console.log(resultado)
        if (resultado < 0) {
            setCategoriaSelecionada('PG');
            setIndexCategoria(4)
            return;
        } else if (resultado > 4) {
            setCategoriaSelecionada('PI');
            setIndexCategoria(0)
            return;
        }
        else {
            var _codCategoria = codCategorias[resultado];
            setCategoriaSelecionada(_codCategoria);
            setIndexCategoria(resultado)
            return;
        }
    }

    const salvarTriagem = async () => {
        setLoadTriagem(true)
        const dataTriagem = {
            id_trabalho: trabalho.id,
            descricao: triagem.comentario,
            aprovado: triagem.aprovado,
            id_usuario: parseInt(usuario.id)
        }
        const cadastrado = await triagemService.triagem(dataTriagem);

        if (cadastrado.status === 201) {
            await getTrabalhos(usuario.id);
            closeTriagem();
        }
        setLoad(false)
    }

    const closeTriagem = () => {
        setTrabalho({
            aprovado: false,
            comentario: ''
        });
        setComentarios([])
        setPopUpTriagem(false);

    }

    const openTriagem = async (tralho) => {
        setTrabalho(tralho);
        await getComentarios(tralho.id);
        setPopUpTriagem(true);

    }

    const onFileChange = event => {

        // Update the state
        // console.log(event.target.files[0])
        setArquivo(event.target.files[0]);
    };

    const corrigirTrabalho = async () => {

        setLoadTriagem(true);
        var data = new FormData();
        data.append("arquivo", arquivo);

        const respondido = await trabalhoService.responderTriagem(trabalho.id, data);

        if (respondido.status === 200) {
            await getTrabalhos(usuario.id);
            setArquivo(null);
            closeTriagem();
        }

        setLoadTriagem(false);


    }

    const openDetalhes = (trabalho) => {
        setTrabalho(trabalho);
        setPopUpDetalhes(true);
    }

    const closeDetalhes = (trabalho) => {
        setTrabalho(null);
        setPopUpDetalhes(false);
    }

    const getComentarios = async (idTrabalho) => {
        const response = await triagemService.detalhes(idTrabalho);

        if (response.status === 200) {
            var _detalhes = await response.json();
            setComentarios(_detalhes);
        }
    }

    return (<>
        {
            popUpTriagem ? <Dialog open={true}
                disableBackdropClick
                disableEscapeKeyDown
                aria-labelledby="confirmation-dialog-title"
                maxWidth='xl'
            >

                <DialogTitle children={`Corrigir Trabalho`} onClose={() => { closeTriagem() }}></DialogTitle>
                <DialogContent dividers>


                    <div style={{ overflowY: 'auto', padding: '12px', height: '350px', width: '100%'}}>
                        <div>
                            <label>
                                Anexar arquivo: {arquivo?.name}
                                <input
                                    id="arquivo"
                                    name="arquivo"
                                    type="file"
                                    onChange={onFileChange}
                                    accept="application/pdf" />
                            </label>
                        </div>

                        <div style={{ paddingRight: '10px' }}>
                            {loadTriagem ? <LoadComponent /> : <input type="submit" value="Salvar" class="trabalho-item-btn" onClick={() => corrigirTrabalho()}></input>}

                        </div>

                        <Grid item xs={12}>
                            <div>

                                <List>

                                    {comentarios.map((i) => {

                                        return (
                                            <>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemAvatar>
                                                        <Avatar alt="Remy Sharp" />
                                                    </ListItemAvatar>

                                                    <ListItemText
                                                        primary={i.autor}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"

                                                                    color="textPrimary"
                                                                >
                                                                    {i.data}
                                                                </Typography>
                                                                {` — ${i.descricao}`}
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider variant="inset" component="li" />
                                            </>)
                                    })
                                    }
                                </List>

                            </div>
                        </Grid>

                    </div>


                    {/*
                <div style={{ float: 'right', paddingRight: '10px' }}>
                    <input type="submit" value="Cancelar" class="trabalho-item-btn" onClick={() => closeTriagem()}></input>

                    </div>
                    */}
    
                </DialogContent>
            </Dialog>: false
        }

        {popUpDetalhes ?
            <Dialog open={true}
                disableBackdropClick
                disableEscapeKeyDown
                aria-labelledby="confirmation-dialog-title"
            >

                <DialogTitle children={`Detalhes`} onClose={() => { closeDetalhes() }}></DialogTitle>
                <DialogContent dividers>

                    <div style={{ overflowY: 'auto', padding: '12px', height: '300px', width: '450px' }}>
                    <label>Nome: {trabalho?.descricao} </label><br />
                    <label>Unidade: {trabalho?.unidade} </label><br />
                    <label>Área: {trabalho?.area} </label><br />
                    <label>Líder: {trabalho?.lider} </label><br />
                    <label>Título do trabalho: {trabalho?.titulo}</label><br />
                    <label>Status: {statusUtil.getSatatus(trabalho?.status)}</label><br />

                        {/*
                   <div>
                            <input type="submit" value="Fechar" class="trabalho-item-btn" onClick={() => closeDetalhes()}></input>
                   </div>
                        */}
                </div>



         
             </DialogContent>
            </Dialog> : null
        }


        <h2 class="categorias-titulo">Bem-vindo(a), {usuario?.nome}</h2>
        {load === false && trabalhos.length === 0 &&
            < center >
            <p>
                Você ainda não tem trabalhos inscritos. Acesse as informações do Prêmio no menu superior.
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </center>
        }
        {load === false && trabalhos.length > 0 &&
            <section class="categorias">


                <div class="container-icons-diferenciais">

                    <div class="container-seta container-seta-direita" onClick={() => sliderCategorias(1)}></div>
                    <div class="container-seta container-seta-esquerda" onClick={() => sliderCategorias(-1)}></div>
                    <div class="seta seta-direita" onClick={() => sliderCategorias(1)}>
                        <img src="assets/img/seta-swiper.png" />
                    </div>
                    <div class="seta seta-esquerda" onClick={() => sliderCategorias(-1)}>
                        <img src="assets/img/seta-swiper.png" />
                    </div>

                    <div class="swiper-mobile swiper mySwiper">
                        <div class="swiper-wrapper">
                            {

                                indexCategoria == 0 ? (
                                    <div class="swiper-slide">
                                        <div class={categoriaSelecionada === 'PI' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                            <div class="moldura-icon">
                                                <img src={categoriaSelecionada === 'PI' ? require("../../assets/img/icones-categorias/icone-produtividade-active.png") : require("../../assets/img/icones-categorias/icone-produtividade.png")} alt="" />
                                            </div>
                                            <span>Produtividade e inovação</span>
                                        </div>
                                    </div>) : null
                            }
                            {
                                indexCategoria == 1 ? (<div class="swiper-slide">
                                    <div class={categoriaSelecionada === 'CS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                        <div class="moldura-icon">
                                            <img src={categoriaSelecionada === 'CS' ? require("../../assets/img/icones-categorias/icone-crescimento-active.png") : require("../../assets/img/icones-categorias/icone-crescimento.png")} alt="" />
                                        </div>
                                        <span>Crescimento sustentável</span>
                                    </div>
                                </div>) : null
                            }
                            {
                                indexCategoria == 2 ?
                                    (<div class="swiper-slide">
                                        <div class={categoriaSelecionada === 'SS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                            <div class="moldura-icon">
                                                <img src={categoriaSelecionada === 'SS' ? require("../../assets/img/icones-categorias/icone-saude-active.png") : require("../../assets/img/icones-categorias/icone-saude.png")} alt="" />
                                            </div>
                                            <span>Saúde e segurança</span>
                                        </div>
                                    </div>) : null
                            }
                            {
                                indexCategoria == 3 ?
                                    (<div class="swiper-slide">
                                    <div class={categoriaSelecionada === 'IC' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                        <div class="moldura-icon">
                                            <img src={categoriaSelecionada === 'IC' ? require("../../assets/img/icones-categorias/icone-iniciativas-active.png") : require("../../assets/img/icones-categorias/icone-iniciativas.png")}  alt="" />
                                            </div>
                                            <span>Iniciativas corporativas</span>
                                        </div>
                                    </div>
                                    ) : null
                            }
                            {
                                indexCategoria == 4 ? (
                                    <div class="swiper-slide">
                                        <div class={categoriaSelecionada === 'PG' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                            <div class="moldura-icon">
                                                <img src={categoriaSelecionada === 'PG' ? require("../../assets/img/icones-categorias/icone-acoes-active.png") : require("../../assets/img/icones-categorias/icone-acoes.png")} alt="" />
                                            </div>
                                            <span>Pequenas ações<br/> grandes feitos</span>
                                        </div>
                                    </div>) : null
                            }
                        </div>

                    </div>
                    <div class="container swiper-desktop swiper">
                        <div class={categoriaSelecionada === 'PI' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('PI')}>
                            <div class="moldura-icon">
                                <img src={categoriaSelecionada === 'PI' ? require("../../assets/img/icones-categorias/icone-produtividade-active.png") : require("../../assets/img/icones-categorias/icone-produtividade.png")} alt="" />
                            </div>
                            <span>Produtividade e inovação</span>
                        </div>
                        <div class={categoriaSelecionada === 'CS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('CS')}>
                            <div class="moldura-icon">
                                <img src={categoriaSelecionada === 'CS' ? require("../../assets/img/icones-categorias/icone-crescimento-active.png") : require("../../assets/img/icones-categorias/icone-crescimento.png")} alt="" />
                            </div>
                            <span>Crescimento sustentável</span>
                        </div>
                        <div class={categoriaSelecionada === 'SS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('SS')}>
                            <div class="moldura-icon">
                                <img src={categoriaSelecionada === 'SS' ? require("../../assets/img/icones-categorias/icone-saude-active.png") : require("../../assets/img/icones-categorias/icone-saude.png")} alt="" />
                            </div>
                            <span>Saúde e segurança</span>
                        </div>
                        <div class={categoriaSelecionada === 'IC' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('IC')}>
                            <div class="moldura-icon">
                                <img src={categoriaSelecionada === 'IC' ? require("../../assets/img/icones-categorias/icone-iniciativas-active.png") : require("../../assets/img/icones-categorias/icone-iniciativas.png")} alt="" />
                            </div>
                            <span>Iniciativas corporativas</span>
                        </div>
                        <div class={categoriaSelecionada === 'PG' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('PG')}>
                            <div class="moldura-icon">
                                <img src={categoriaSelecionada === 'PG' ? require("../../assets/img/icones-categorias/icone-acoes-active.png") : require("../../assets/img/icones-categorias/icone-acoes.png")} alt="" />
                            </div>
                        <span>Pequenas ações grandes feitos</span>
                        </div>
                    </div>
                </div>

                <img class={"categoria-selecionado-" + categoriaSelecionada} src="assets/img/categoria-selecionado.png" />

                {load ? <div class="container container-categoria">
                    <div style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}><LoadComponent /></div>
                </div> :

                    <div class="trabalhos">
                        {trabalhos.filter(x => x.categoria.cod === categoriaSelecionada).map((tr) =>
                            <div class="trabalho-item">
                                <div class="trabalho-item-titulo">
                                    {tr.titulo}
                                </div>
                                <div class="trabalho-item-titulo">
                                    {tr.autor.nome}
                                </div>
                                <div class="trabalho-item-titulo">
                                    Status: {statusUtil.getSatatus(tr?.status)}
                                </div>


                                <div class="trabalho-item-btn" onClick={() => openDetalhes(tr)}>
                                    Detalhes
                                </div>

                                <a href={`api/anexos/${tr.id}`} download>
                                    <div class="trabalho-item-btn">
                                        {categoriaSelecionada === "PG" ? "Baixar Video" : "Baixar PDF"}
                                    </div>
                                </a>
                                {tr.status === 'NP' ?

                                    <div onClick={() => openTriagem(tr)} class="trabalho-item-btn trabalho-item-btn-avaliar">
                                        Corrigir Trabalho
                                    </div>
                                    : null
                                }
                            </div>
                        )
                        }

                    </div>
                }

            </section>
        }
    </>);
}