
class UsuarioService{

    async usuarios() {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/usuarios`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

    async perfis(idUsuario) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/usuarios/perfis/${idUsuario}`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

    async addPerfil(perfil) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/usuarios/perfis`,
                {
                    method: 'POST',
                    body: perfil,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

    async removePerfil(idUsuario, perfil) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/usuarios/perfis/${idUsuario}/${perfil}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

}
export default new UsuarioService;