class ConfiguracaoService {

    async configuracoes() {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/configuracao`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            if (response.status === 200)
                return await response.json();
        } catch (error) {
            console.log(error)
        }
    }

    async configuracao(config) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/configuracao/${config}`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            if (response.status === 200)
                return await response.json();

        } catch (error) {
            console.log(error)
        }
    }

    async update(config, id) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/configuracao/${id}`,
                {
                    method: 'PUT',
                    body: config,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

}
export default new ConfiguracaoService();