import React, { useEffect, useState } from 'react';
import loginService from '../../services/login/login-service';
import Alert from '../../components/Alert';
import ReCAPTCHA from "react-google-recaptcha";
import '../../assets/styles/css/pop-up.css';
import TermoComponent from '../termo/termo';
import LoadComponent from '../../components/Load';
import { Link } from '@material-ui/core';
import usuarioService from '../../services/usuario/usuario-service';
import platform from 'platform';

export default function LoginPage(props) {

    const [inscricao, setInscricao] = useState({
        nome: '',
        cpf: '',
        email: ''
    });

    const [login, setLogin] = useState({
        usuario: '',
        senha: '',
    });

    const [valida, setValida] = useState(false);
    const [alerta, setAlerta] = useState({
        show: false,
        mensagem: '',
        tipo: ''
    });

    const [alertaLogin, setAlertaLogin] = useState({
        show: false,
        mensagem: '',
        tipo: ''
    });

    const [captchaValue, setCaptchaValue] = useState('');
    const [captchaValueCadastro, setCaptchaValueCadastro] = useState('');

    const [usuario, setUsuario] = useState(null);

    const [novaSenha, setNovaSenha] = useState({
        senha: '',
        confirmarSenha: '',
    });

    const [senhaForte, setSenhaForte] = useState(false);

    const [termoAceite, setTermoAceite] = useState(false);
    const [modalTermo, setModalTermo] = useState(false);
    const [loadLogin, setLoadLogin] = useState(false);
    const [loadCadastro, setLoadCadastro] = useState(false);

    useEffect(() => {
        var _plataforma = platform.os.family;
        if (
            String(_plataforma).toLocaleLowerCase() === 'ios' ||
            String(_plataforma).toLocaleLowerCase() === 'android'
        ) {
            document.getElementById("navMenu").style.display = "none";
        }
       

    }, []);

    const formataCPF = () => {

        var cpf = '';
        if (inscricao.cpf) {
            cpf = inscricao.cpf.match(/.{1,3}/g).join(".").replace(/\.(?=[^.]*$)/, "-");
            setInscricao({ ...inscricao, cpf: cpf });
        }
    }

    const formataCPFLogin = () => {

        var cpf = '';
        if (login.usuario) {
            var user = login.usuario ? login.usuario.replace(/[^0-9]/g, '') : '';
            cpf = user.match(/.{1,3}/g).join(".").replace(/\.(?=[^.]*$)/, "-");
            setLogin({ ...login, usuario: cpf });
        }
    }

    const validaInscricao = () => {
        if (!inscricao.nome || !inscricao.email || !inscricao.cpf) {
            setValida(true);
            return true;
        } else if (!captchaValueCadastro)
        {
            setAlerta({
                show: true,
                mensagem: `Valide o Captcha`,
                tipo: ''
            });
            return true;
        }
        else {
            setValida(false);
            return false;
        }
    }
    const teste = async () => {
        var cat = await loginService.categorias();
    }

    const cadastro = async () => {

        setModalTermo(false);
        if (!validaInscricao()) {
            setLoadCadastro(true);
            const responseCadastro = await loginService.cadastrar(inscricao);

            if (responseCadastro.status === 201) {
                limparCampos();
                setAlerta({
                    show: true,
                    mensagem: 'Usuário Cadastrado com Sucesso, aguarde o recebimento do e-mail contendo sua autenticação!',
                    tipo: 'success'
                });
            
            }
            else {
                var data = await responseCadastro.json();

                if (data.code && data.code === 1)
                    setAlerta({
                        show: true,
                        mensagem: `Não foi possível efetuar o cadastro ${data.mensagem}`,
                        tipo: ''
                    });
                else
                    setAlerta({
                        show: true,
                        mensagem: `Não foi possível efetuar o cadastro.`,
                        tipo: ''
                    });


            }

            setLoadCadastro(false);

        }
    }

    const preCadastro = async () => {

        if (!validaInscricao()) {

            setModalTermo(true);

        }
    }

    const validaDadosLogin = () => {
        var mensagem = '';

        if (!login.usuario)
            mensagem += " [Informe o CPF]";
        if (!login.senha)
            mensagem += " [Informe a Senha]";
        if (!captchaValue)
            mensagem += " [Valide o Captcha]";

        if (mensagem) {
            setAlertaLogin({
                show: true,
                mensagem: mensagem,
                tipo: 'error'
            });
            return false;
        }

        return true;


    }
    const loginChange = async () => {
        if (validaDadosLogin()) {
            setLoadLogin(true)
            const loginResult = await loginService.login(login);

            if (loginResult.status === 200) {
                var data = await loginResult.json();
                setUsuario(data);

                await localStorage.setItem("usuario", JSON.stringify(data));

                if (data.primeiro_acesso === false)
                    props.history.push('/usuario');

            } else {
                setAlertaLogin({
                    show: true,
                    mensagem: 'Login ou Senha Inválido',
                    tipo: 'error'
                });
            }
            setLoadLogin(false)
        }
    }
    const onChangeCaptcha = (value) => {
        setCaptchaValue(value);
    }

    const onChangeCaptchaCadastro = (value) => {
        setCaptchaValueCadastro(value);
    }

    const onBlurSenha = () => {
        let regexSenhaForte = /^(?=.*[A-Z])(?=.*[!#@$%&])(?=.*[0-9])(?=.*[a-z]).{8,15}$/;

        if (regexSenhaForte.test(novaSenha.senha))
            setSenhaForte(true)
        else
            setSenhaForte(false);

    }

    const validaNovaSenha = () => {

        var mensagem = '';

        if (novaSenha.senha !== novaSenha.confirmarSenha)
            mensagem = " [As Senhas são diferentes]";
        if (!senhaForte)
            mensagem += " [A Senha é fraca]";

        if (mensagem) {
            setAlertaLogin({
                show: true,
                mensagem: mensagem,
                tipo: 'error'
            });
            return false;
        }

        return true;

    }

    const onChangeNovaSenha = async () => {
        if (validaNovaSenha()) {
            const resultNovaSenha = await loginService.novaSenha({
                id: usuario.id,
                senha: novaSenha.senha
            });

            if (resultNovaSenha.status !== 200) {
                setAlertaLogin({
                    show: true,
                    mensagem: 'Não foi possível alterar a senha',
                    tipo: 'error'
                });

                return false;
            } else {
                props.history.push('/usuario')
            }
        }
    }

    const closeModalTermo = () => {
        setModalTermo(false)
    }

    const limparCampos = () => {
        setInscricao({
            nome: '',
            cpf: '',
            email: ''
        });
    }

    const esqueciSenha = async () => {
        if (!captchaValue) {
            setAlertaLogin({
                show: true,
                mensagem: 'Valide o Captcha',
                tipo: 'error'
            });
            return;
        }
        if (!login.usuario) {

            setAlertaLogin({
                show: true,
                mensagem: 'Informe o CPF',
                tipo: 'error'
            });
            return;
        } else {
            const response = await loginService.esqueciASenha(login.usuario);
            if (response.status === 200) {
                setAlertaLogin({
                    show: true,
                    mensagem: 'Nova senha enviada para o e-mail.',
                    tipo: 'success'
                });
                return;
            } else {
                setAlertaLogin({
                    show: true,
                    mensagem: 'Não foi possível enviar a senha.',
                    tipo: 'error'
                });
            }
        }
    }

    return (
        <>
            {
                modalTermo ?    <article class="pop-up">

                    <fieldset>
                        <div style={{ overflowY: 'auto', padding: '12px', height: '300px', width: '100%', border: 'solid 1px #eee' }}>

                            <TermoComponent />

                        </div>
                        <label><div>
                            <input type="checkbox" checked={termoAceite} onChange={() => setTermoAceite(!termoAceite)} name="" />
                            Li e aceito o Termo de Adesão e Participação</div>
                        </label><br />
                    </fieldset>

                    {termoAceite == false ? <button class="btn-enviar" onClick={closeModalTermo}>Cancelar</button> : null}
                    {termoAceite ? <button class="btn-enviar" onClick={cadastro}>Enviar</button> : null}


                </article> : null
            }

            <section class="conteudo">
                <h1>
                    Já faz parte ou é o seu primeiro acesso? <br />
                    Vamos, é rápido e fácil!
                </h1>
            </section>
            <section class="login">
                <div class="login-item">

                    <h1>Cadastre-se</h1>
                    <label>Nome completo*:</label><br />
                    {valida && !inscricao.nome ? <><label style={{ color: 'red' }}>Preencha o campo Nome</label><br /></> : null}
                    <input style={{ width: '75%' }} type="text" name="" value={inscricao.nome} onChange={(e) => setInscricao({ ...inscricao, nome: e.target.value })} /><br />
                    <label>CPF*:</label><br />
                    {valida && !inscricao.cpf ? <><label style={{ color: 'red' }}>Preencha o campo CPF</label><br /></> : null}
                    <input style={{ width: '75%' }} type="text" name="" maxLength={11} value={inscricao.cpf} onChange={(e) => setInscricao({ ...inscricao, cpf: e.target.value })} onBlur={formataCPF} /><br />
                    <label>Email*:</label><br />
                    {valida && !inscricao.email ? <><label style={{ color: 'red' }}>Preencha o campo e-mail</label><br /></> : null}
                    <input style={{ width: '75%' }} type="email" name="" value={inscricao.email} onChange={(e) => setInscricao({ ...inscricao, email: e.target.value })} /><br />
                    <div class="captcha">
                        <ReCAPTCHA
                            sitekey="6Ld4IeodAAAAAN-IomjISwGSNUSCZtT8_tkJu5Tt"
                            onChange={onChangeCaptchaCadastro}
                        />
                    </div>

                    {/*
                    <label><div>
                        <input type="checkbox" name="" />
                        Li e aceito o Termo de Adesão e Participação</div>
                    </label><br />
                    */}

                    {
                        alerta.show ? <><Alert mensagem={alerta.mensagem} tipo={alerta.tipo} style={{ width: '70%' }} /></> : null
                    }
                    {loadCadastro ? <LoadComponent /> : <input type="submit" value="Cadastrar" onClick={preCadastro} />}

                </div>

                <hr/>
                <div style={{width: '5%'}}>
                     </div>
                <div class="login-item">
                    {!usuario && !usuario?.primeiro_acesso && <>
                        <h1>Login</h1>
                        <label>CPF:</label><br />
                        <input style={{ width: '75%' }} type="text" name="" value={login.usuario} onBlur={formataCPFLogin} onChange={(e) => setLogin({ ...login, usuario: e.target.value })} /><br />
                        <label>Senha:</label><br />
                        <input style={{ width: '75%' }} type="password" name="" value={login.senha} onChange={(e) => setLogin({ ...login, senha: e.target.value })} /><br />
                        <a onClick={() => { esqueciSenha(); }} style={{ color: '#ff8100', cursor: 'pointer' }}><u>Esqueci a Senha</u></a>
                        <div class="captcha">
                            <ReCAPTCHA
                                sitekey="6Ld4IeodAAAAAN-IomjISwGSNUSCZtT8_tkJu5Tt"
                                onChange={onChangeCaptcha}
                            />
                        </div>
                        {
                            alertaLogin.show ? <><Alert mensagem={alertaLogin.mensagem} tipo={alertaLogin.tipo} style={{ width: '70%' }} /></> : null
                        }
                        {loadLogin ? <LoadComponent /> : < input type="submit" value="Acessar" onClick={loginChange} />}
                    </>
                    }
                    {
                        usuario && usuario.primeiro_acesso &&
                        <>
                            <h1>Alterar Senha</h1>
                            <label>Nova Senha:</label><br />
                            {!senhaForte ? <><label style={{ color: 'red' }}>A senha deve conter números e no minimo 1 caracter especial, 1 letra maiuscula e 8 digitos.</label><br /></> : null}
                            <input style={{ width: '75%' }} type="password" name="" value={novaSenha.senha} onBlur={onBlurSenha} onChange={(e) => setNovaSenha({ ...novaSenha, senha: e.target.value })} /><br />
                            <label>Confirmar Senha:</label><br />
                            <input style={{ width: '75%' }} type="password" name="" value={novaSenha.confirmarSenha} onChange={(e) => setNovaSenha({ ...novaSenha, confirmarSenha: e.target.value })} /><br />
                            <br />
                            {
                                alertaLogin.show ? <><Alert mensagem={alertaLogin.mensagem} tipo={alertaLogin.tipo} style={{ width: '70%' }} /></> : null
                            }

                            <input type="submit" value="Alterar a Senha" onClick={onChangeNovaSenha} />
                        </>

                    }
                 
                </div>
            </section>
           

        </>
    );
}