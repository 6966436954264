import React, { useEffect, useState } from 'react';
import platform from 'platform';


export default function DuvidaPage(props) {

    useEffect(() => {
        var _plataforma = platform.os.family;
        if (
            String(_plataforma).toLocaleLowerCase() === 'ios' ||
            String(_plataforma).toLocaleLowerCase() === 'android'
        ) {
            document.getElementById("navMenu").style.display = "none";
        }

        init();

    }, []);
    const init = async () => {

        var usuario = await localStorage.getItem("usuario");
        console.log(usuario)
        if (!usuario) {
            await localStorage.setItem('menu', 'login');
            props.history.push('/login')
        }

    }

    const controlarCaixa = (idTexto, idImagem) => {
        const temp = document.getElementById(idTexto).style.display;
        if (temp == "" || temp == "none") {
            document.getElementById(idTexto).style.display = "block";
            document.getElementById(idImagem).style.transform = "rotate(90deg)";
        }
        else {
            document.getElementById(idTexto).style.display = "none";
            document.getElementById(idImagem).style.transform = "rotate(0deg)";
        }
    }

    return (
        <>

            <section class="apresentacao-duvida">
                <h1>
                    Tire suas dúvidas sobre o prêmio
			</h1>
                <p>
                    Confira abaixo as respostas para as dúvidas mais frequentes.
			</p>
            </section>


            <section class="duvida">
                <div class="duvida-item">
                    <div onClick={() => controlarCaixa('txtCaixaDuvida1', 'imgSetaDuvida1')} class="duvida-item-header">
                        <div class="duvida-item-header-texto">
                            Posso inscrever o mesmo projeto em mais de uma categoria?
					</div>
                        <div class="duvida-item-header-icone">
                            <img id="imgSetaDuvida1" src={require("../../assets/img/seta.png")} />
                        </div>
                    </div>
                    <div id="txtCaixaDuvida1" class="duvida-item-content">
                        Não. Cada projeto só poderá ser inscrito em uma única categoria. Acesse a página Categorias, leia as descrições e inscreva seu projeto na categoria mais adequada.
				</div>
                </div>
                <div class="duvida-item">
                    <div onClick={() => controlarCaixa('txtCaixaDuvida2', 'imgSetaDuvida2')} class="duvida-item-header">
                        <div class="duvida-item-header-texto">
                            Os projetos devem ser individuais ou podem ser inscritos em grupo?
					</div>
                        <div class="duvida-item-header-icone">
                            <img id="imgSetaDuvida2" src={require("../../assets/img/seta.png")} />
                        </div>
                    </div>
                    <div id="txtCaixaDuvida2" class="duvida-item-content">
                        A Atvos incentiva o trabalho em equipe e reconhece que ele é fundamental para alcançarmos resultados. Por isso, os projetos podem conter o nome de todos os integrantes que participaram do desenvolvimento do projeto. Porém, é preciso que o grupo eleja um integrante responsável para inscrever o projeto.
				</div>
                </div>
                <div class="duvida-item">
                    <div onClick={() => controlarCaixa('txtCaixaDuvida3', 'imgSetaDuvida3')} class="duvida-item-header">
                        <div class="duvida-item-header-texto">
                            Já tenho um relatório pronto com todas as informações sobre o meu projeto. Posso enviá-lo?
					</div>
                        <div class="duvida-item-header-icone">
                            <img id="imgSetaDuvida3" src={require("../../assets/img/seta.png")} />
                        </div>
                    </div>
                    <div id="txtCaixaDuvida3" class="duvida-item-content">
                        Não. Para as categorias Produtividade e Inovação, Crescimento Sustentável, Saúde e Segurança e Iniciativas Corporativas só será aceito o envio do arquivo-modelo preenchido. Ele está disponível na página de inscrição. Faça o download, preencha de forma detalhada e o anexe para realizar sua inscrição.
				</div>
                </div>
                <div class="duvida-item">
                    <div onClick={() => controlarCaixa('txtCaixaDuvida4', 'imgSetaDuvida4')} class="duvida-item-header">
                        <div class="duvida-item-header-texto">
                            Um fornecedor/terceiro auxiliou no desenvolvimento do meu projeto. Ele pode participar do prêmio?
					</div>
                        <div class="duvida-item-header-icone">
                            <img id="imgSetaDuvida4" src={require("../../assets/img/seta.png")} />
                        </div>
                    </div>
                    <div id="txtCaixaDuvida4" class="duvida-item-content">
                        O prêmio Destaque Atvos é exclusivo para integrantes da empresa, então, fornecedores/terceiros não podem se inscrever. Porém, reconhecemos que o apoio dos nossos parceiros é essencial para nossas atividades. Caso um parceiro externo tenha contribuído de forma significativa para o sucesso do seu projeto, você pode mencioná-lo em sua descrição.
				</div>
                </div>
                <div class="duvida-item">
                    <div onClick={() => controlarCaixa('txtCaixaDuvida5', 'imgSetaDuvida5')} class="duvida-item-header">
                        <div class="duvida-item-header-texto">
                            Posso enviar fotos para ilustrar o meu projeto e mostrá-lo na prática?
					</div>
                        <div class="duvida-item-header-icone">
                            <img id="imgSetaDuvida5" src={require("../../assets/img/seta.png")} />
                        </div>
                    </div>
                    <div id="txtCaixaDuvida5" class="duvida-item-content">
                        As fotos deverão ser inseridas no arquivo-modelo. Dessa forma, toda a documentação do seu projeto estará em um mesmo local, facilitando a avaliação.
                        Na categoria “Pequenas ações, grandes feitos” não serão aceitas fotos, apenas um vídeo com até 3 minutos, como mostra o regulamento.
				</div>
                </div>

                <div class="duvida-item">
                    <div onClick={() => controlarCaixa('txtCaixaDuvida6', 'imgSetaDuvida6')} class="duvida-item-header">
                        <div class="duvida-item-header-texto">
                            Haverá um vencedor por unidade ou escritório?
					</div>
                        <div class="duvida-item-header-icone">
                            <img id="imgSetaDuvida6" src={require("../../assets/img/seta.png")} />
                        </div>
                    </div>
                    <div id="txtCaixaDuvida6" class="duvida-item-content">
                        Não. Cada categoria terá um único vencedor, independentemente da unidade/escritório. Por isso é muito importante identificar em qual categoria seu projeto melhor se encaixa.
				</div>
                </div>

            </section>

            <section class="rodape-duvidas">
                <div class="rodape-duvidas-texto">
                    <label>Em caso de dúvidas, entre em contato com a área de Comunicação pelo</label>
			</div>
                <div class="rodape-duvidas-telefone">
                    <a href="https://api.whatsapp.com/send?phone=5511996562927" target="_blank"><label>(11)99656-2927</label></a><br />
                    <label>comunicacao@atvos.com</label>
			</div>
            </section>

        </>
    );

}