import React, { useEffect, useState } from 'react';
import platform from 'platform';

function calculateTimeLeft() {
    const year = new Date().getFullYear();
    const difference = +new Date(`${year}-06-05`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    }

    return timeLeft;
}

export default function HomePage() {

    const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());
    const [plataforma, setPlataforma] = useState('');
    useEffect(() => {
        var _plataforma = platform.os.family;
        if (
            String(_plataforma).toLocaleLowerCase() === 'ios' ||
            String(_plataforma).toLocaleLowerCase() === 'android'
        ) {
            document.getElementById("navMenu").style.display = "none";
        }

        setPlataforma(_plataforma);

        console.log(_plataforma);

    }, []);

    React.useEffect(() => {
       
        const id = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => {
            clearTimeout(id);
        };
    });
    const timerComponents = Object.keys(timeLeft).map(interval => {
        if (!timeLeft[interval]) {
            return;
        }

        console.log(interval)
        /*
        return (
            <span>
                {timeLeft[interval]} {interval}{" "}
            </span>
        )
        */

        return (
            <div class="banner-contagem-cronometro-horarios">
                <div class="banner-contagem-cronometro-horarios-itens">
                    {timeLeft.days}<br></br>
                    <span>DIAS</span>
                </div>
                <div class="banner-contagem-cronometro-horarios-itens">
                    {timeLeft.hours}<br></br>
                    <span>HORAS</span>
                </div>
                <div class="banner-contagem-cronometro-horarios-itens">
                    {timeLeft.minutes}<br></br>
                    <span>MINUTOS</span>
                </div>
                {<div class="banner-contagem-cronometro-horarios-itens">
                    {timeLeft.seconds}<br></br>
                    <span>SEGUNDOS</span>
                </div>
                }
            </div>
            );
    });
    return (
        <>
            <section class="banner">
                <div class="banner-foto"></div>
                <div class="banner-contagem">
                    <div class="banner-contagem-titulo">
                        <span>O</span> AMANHÃ
                    </div>
                    <div class="banner-contagem-subtitulo">
                        PEDE UMA NOVA
                    </div>
                    <div class="banner-contagem-titulo">
                        ENERGIA.
                    </div>
                    <div class="banner-contagem-cronometro">
                        <div class="banner-contagem-cronometro-titulo">
                            {timerComponents.length ? 'A votação aberta termina em' : ''}
                        </div>
                        <div>
                            {timerComponents.length ? timerComponents[0] : <span>As inscrições para o Prêmio Destaque Atvos 2022 estão encerradas.</span>}
                        </div>
                        {/*
                        <div class="banner-contagem-cronometro-horarios">
                            <div class="banner-contagem-cronometro-horarios-itens">
                                {timeLeft.days}<br></br>
                                <span>DIAS</span>
                            </div>
                            <div class="banner-contagem-cronometro-horarios-itens">
                                {timeLeft.hours}<br></br>
                                <span>HORAS</span>
                            </div>
                            <div class="banner-contagem-cronometro-horarios-itens">
                                {timeLeft.minutes}<br></br>
                                <span>MINUTOS</span>
                            </div>
                            <div class="banner-contagem-cronometro-horarios-itens">
                                {timeLeft.seconds}<br></br>
                                <span>SEGUNDOS</span>
                            </div>
                        </div>
                        */}
                    </div>
                </div>
            </section>
            <section class="apresentacao">
                <h1>
                    Bem-vindo(a) ao<br></br>
                    Prêmio Destaque Atvos 2022!
                </h1>
                <p>
                    Quando compartilhamos bons resultados, temos o poder de ensinar, aprender e inovar. O amanhã pede uma nova energia e nós estamos prontos para renová-lo com criatividade e
                    inovação.
                </p><br/>
                <p>
                    Conheça abaixo as categorias e veja em qual delas você e seu trabalho melhor se encaixam. Lembre-se de ler o regulamento com todas as informações antes de fazer a
                    inscrição.
                </p><br />
                <p>
                    Os ganhadores de cada categoria serão premiados com 1 Troféu Destaque Atvos por integrante, 1 Tablet Samsung por trabalho e 1 Vale-compras de R$ 1.000 (mil reais) por trabalho.
                </p><br />
            </section>

            <section class="diferenciais">
                <div class="diferenciais-item">
                    <div class="diferenciais-item-icone">
                        <a href='/categorias'><img src={require('../../assets/img/icone-produtividade.png')} /></a>
                    </div>
                    <div class="diferenciais-item-titulo">
                        PRODUTIVIDADE <br></br>
                        E INOVAÇÃO
                    </div>
                    <div class="diferenciais-item-texto">
                        Impactos positivos em produtividade e eficiência
                    </div>
                </div>
                <div class="diferenciais-item">
                    <div class="diferenciais-item-icone">
                        <a href='/categorias'><img src={require('../../assets/img/icone-crescimento.png')} /></a>
                    </div>
                    <div class="diferenciais-item-titulo">
                        CRESCIMENTO<br></br>
                        SUSTENTÁVEL
                    </div>
                    <div class="diferenciais-item-texto">
                        Ações socioambientais para um amanhã melhor
                    </div>
                </div>
                <div class="diferenciais-item">
                    <div class="diferenciais-item-icone">
                        <a href='/categorias'><img src={require('../../assets/img/icone-saude.png')} /></a>
                    </div>
                    <div class="diferenciais-item-titulo">
                        SAÚDE E<br></br>
                        SEGURANÇA
                    </div>
                    <div class="diferenciais-item-texto">
                        Melhorias para qualidade de vida e trabalho dos integrantes
                    </div>
                </div>
                <div class="diferenciais-item">
                    <div class="diferenciais-item-icone">
                        <a href='/categorias'><img src={require('../../assets/img/icone-iniciativas.png')} /></a>
                    </div>
                    <div class="diferenciais-item-titulo">
                        INICIATIVAS<br></br>
                        CORPORATIVAS
                    </div>
                    <div class="diferenciais-item-texto">
                        Crescimento e fortalecimento do negócio
                    </div>
                </div>
                <div class="diferenciais-item">
                    <div class="diferenciais-item-icone">
                        <a href='/categorias'> <img src={require('../../assets/img/icone-acoes.png')} /></a>
                    </div>
                    <div class="diferenciais-item-titulo">
                        PEQUENAS AÇÕES<br></br>
                        GRANDES FEITOS

                    </div>
                    <div class="diferenciais-item-texto">
                        Ações simples que geram grandes resultados
                    </div>
                </div>
            </section>
        </>
    );
}