import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import usuarioService from '../../services/usuario/usuario-service';
import UsuarioPerfilComponent from './components/usuario-perfil-component';
import { IconButton } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

export default function UsuarioAdmPage(props) {

    const [usuarios, setUsuarios] = useState([]);
    const [dialogPerfil, setDialogPerfil] = useState(false); 
    const [idUsuario, setIdUsuario] = useState(null);

    useEffect(()=>{
        init();
    },[]);

    const handleOpenDialogPerfil = (idUsuario) =>{
        setIdUsuario(idUsuario);
        setDialogPerfil(true);
    }

    const handleCloseDialogPerfil = () =>{
        setIdUsuario(null);
        setDialogPerfil(false)
    }

    const init = async () => {

        await getUsuarios();

    }

    const getUsuarios = async () => {
        const response = await usuarioService.usuarios();

        if(response.status === 200){
            var data = await response.json();

            setUsuarios(data);
        }
    }

    return(<>
            <MaterialTable
            title={'Usuarios'}
            columns={[
                {title: 'Nome', field: 'nome'},
                {title: 'E-mail', field: 'email'},
                {title: 'CPF', field: 'cpf'},
                {
                    title: 'Perfil', 
                    render: rowData =>{
                        return (<IconButton color="default" onClick={()=>handleOpenDialogPerfil(rowData.id)}>
                            <LockIcon />
                        </IconButton>)
                    }
                },
                {title: 'Data de Cadastro', field: 'dt_cadastro'},
                {title: 'Data de Alteração', field: 'dt_alteracao'},
            ]}
            data={usuarios}
        />
        <UsuarioPerfilComponent 
            isOpen={dialogPerfil} 
            close={handleCloseDialogPerfil} 
            idUsuario={idUsuario} 
        />
    </>);
}