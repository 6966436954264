
class LoginService {



    async categorias() {

       // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/categorias`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            if (response.status === 200)
                return await response.json();
        } catch (error) {
            console.log(error)
        }
    }

    async cadastrar(cadastro) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/usuarios/cadastrar`,
                {
                    method: 'POST',
                    body: JSON.stringify(cadastro),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

    async login(login) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/usuarios/login`,
                {
                    method: 'POST',
                    body: JSON.stringify(login),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

    async esqueciASenha(cpf) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/usuarios/esquecisenha/${cpf}`,
                {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

    async novaSenha(novaSenha) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/usuarios/novasenha`,
                {
                    method: 'PUT',
                    body: JSON.stringify(novaSenha),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

    /*
    async getArquivo(idSolicitacao) {

        let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`${window.$API_PORTALS}/v1/solicitacaonf/cartacorrecao/arquivo/${idSolicitacao}`,
                {
                    method: 'GET',
                    headers: _headers
                });

            if (response.status === 200)
                return await response.json();
        } catch (error) {
            console.log(error)
        }
    }


    async getCartaBySolicitacao(idSolicitacao) {
        let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`${window.$API_PORTALS}/v1/solicitacaonf/cartacorrecao/bySolicitacao/${idSolicitacao}`,
                {
                    method: 'GET',
                    headers: _headers
                });

            if (response.status === 200)
                return await response.json();
        } catch (error) {
            console.log(error)
        }
    }

    async updateCarta(id, carta) {

        let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`${window.$API_PORTALS}/v1/solicitacaonf/cartacorrecao/atualizar/${id}`,
                {
                    method: 'PUT',
                    headers: _headers,
                    body: carta
                });

            if (response.status === 200)
                return await response.json();
            else
                return null;

        } catch (error) {
            console.log(error)
        }
    }



    */

}

export default new LoginService();
