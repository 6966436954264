class StatusUtil {

    getSatatus = (codStatus) => {
        switch (String(codStatus).toUpperCase()) {
            case 'AB':
                return 'Aberto';
            case 'AP':
                return 'Aprovado';
            case 'TR':
                return 'Triagem';
            case 'NP':
                return 'Não Aprovado';
            case 'VT':
                return 'Votação';
            case 'PA':
                return 'Primeira Avaliação';
            case 'SA':
                return 'Segunda Avaliação';
            case 'FN':
                return 'Finalizado';
            default:
                return '';

        }
    }

}
export default new StatusUtil;