import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import usuarioService from '../../services/usuario/usuario-service';
import UsuarioPerfilComponent from './components/usuario-perfil-component';
import { IconButton, TextField, DatePicker } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import configuracaoService from '../../services/Configuracao/configuracao-service';

export default function ConfigPage(props) {

    const [usuarios, setUsuarios] = useState([]);
    const [dialogPerfil, setDialogPerfil] = useState(false);
    const [idUsuario, setIdUsuario] = useState(null);
    const [configuracoes, setConfiguracoes] = useState([]);

    useEffect(() => {
        init();
    }, []);

   
    const init = async () => {

        await getConfiguracoes();

    }

    const getConfiguracoes = async () => {
        const response = await configuracaoService.configuracoes();
        setConfiguracoes(response);
        
    }
    const update = async (config, id) => {
        const data = {
            dt_inicio: config.data_inicio,
            dt_fim: config.data_fim
        };

        const atualizado = await configuracaoService.update(JSON.stringify(data), id);

        if (atualizado.status === 200)
            return true;

        return false;

    }

    return (<>
        <MaterialTable
            title={'Configurações'}
            columns={[
                { title: 'Descrição', field: 'descricao', editable: 'never' },
                { title: 'Código', field: 'codigo', editable: 'never' },
                {
                    title: 'Data Inicio', field: 'data_inicio',
                    editComponent: props => {
                        return (
                            <input
                                type="date"
                                value={props.value}
                                onChange={(e) => {
                                    props.onChange(e.target.value)
                                }}
                            />
                       );
                    }
                },
                {
                    title: 'Data Fim',
                    field: 'data_fim',
                    editComponent: props => {
                        return (
                            <input
                                type="date"
                                value={props.value}
                                onChange={(e) => {
                                    props.onChange(e.target.value)
                                }}
                            />
                        );
                    }
                },
                {
                    title: 'Ativo',
                    render: rowData => {
                        return rowData.ativo ? (<CheckBoxIcon />) : (<CheckBoxOutlineBlankIcon />)
                    },
                    editable: 'never'
                },

            ]}
            data={configuracoes}
            editable={{
                onRowUpdate: (newData) =>
                    new Promise((resolve, reject) => {

                        update(newData, newData.id_configuracao).then((result) => {

                            if (result) {
                                getConfiguracoes();
                                resolve();
                            }
                            else
                                return reject();
                        }).catch(error => {
                            console.log(error);
                            return reject();
                        });

                    }),
            }}
        />
      
    </>);
}