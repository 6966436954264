import React, { useEffect, useState } from 'react';
import '../assets/styles/alert.css'; 

export default function Alert({
    mensagem,
    tipo,
    style
}) {

    const [closed, setClosed] = useState(false);

    const close = () => {
        console.log('teste')
        setClosed(true);
    }
    return (
        <div class={"alert" + " " + tipo} style={{ ...style, ...{ display: closed ? 'none' : '' } }}>
            <span class="closebtn" onClick={() => close()}>&times;</span>
            {mensagem}
        </div>
    );
}