import React, { useEffect, useState } from 'react';
import '../../assets/styles/css/categorias.css';
import '../../assets/styles/css/swiper-bundle.min.css';
import categoriaService from '../../services/Categoria/categoria-service';
import cs1 from '../../assets/img/categorias/cs1.jpg';
import cs2 from '../../assets/img/categorias/cs2.jpg';
import ic1 from '../../assets/img/categorias/ic1.jpg';
import ic2 from '../../assets/img/categorias/ic2.jpg';
import pg1 from '../../assets/img/categorias/pg1.jpg';
import pg2 from '../../assets/img/categorias/pg2.jpg';
import pi1 from '../../assets/img/categorias/pi1.jpg';
import pi2 from '../../assets/img/categorias/pi2.jpg';
import ss1 from '../../assets/img/categorias/ss1.jpg';
import ss2 from '../../assets/img/categorias/ss2.jpg';
import platform from 'platform';

export default function CategoriaPage(props) {

    const [categoriaSelecionada, setCategoriaSelecionada] = useState('PI');
    const [codCategorias, setCodCategorias] = useState([
        "PI",
        "CS",
        "SS",
        "IC",
        "PG"
    ]);
    const [categorias, setCategorias] = useState([]);
    const [indexCategoria, setIndexCategoria] = useState(0);

    useEffect(() => {
        var _plataforma = platform.os.family;
        if (
            String(_plataforma).toLocaleLowerCase() === 'ios' ||
            String(_plataforma).toLocaleLowerCase() === 'android'
        ) {
            document.getElementById("navMenu").style.display = "none";
        }
        init()

    }, []);

    const init = async () => {

        var usuario = await localStorage.getItem("usuario");
        console.log(usuario)
        if (!usuario) {
            await localStorage.setItem('menu', 'login');
            props.history.push('/login')
        }

        await localStorage.setItem('menu', 'categoria');
        await getCategorias();
    }

    const handleCategoriaSelecionada = (codCategoria) => {
        setCategoriaSelecionada(codCategoria);

        var index = codCategorias.findIndex(x => x === codCategoria);

        setIndexCategoria(index);
    }

    const getCategorias = async () => {
        const _categoriasResult = await categoriaService.categorias();

        console.log(_categoriasResult)
        setCategorias(_categoriasResult);
    }

    const sliderCategorias = (num) => {
        var resultado = indexCategoria + (num);
        console.log(resultado)
        if (resultado < 0) {
            setCategoriaSelecionada('PG');
            setIndexCategoria(4)
            return;
        } else if (resultado > 4) {
            setCategoriaSelecionada('PI');
            setIndexCategoria(0)
            return;
        }
        else {
            var _codCategoria = codCategorias[resultado];
            setCategoriaSelecionada(_codCategoria);
            setIndexCategoria(resultado)
            return;
        }
    }

    const imgsCategorias = () => {

        var imgs = null;
        if (categoriaSelecionada === 'PI')
            imgs = <>
                <div class="categoria-imagens">
                    <img width="80%" src={pi1} alt="" />
                </div>

                <div class="categoria-imagens">
                    <img width="80%" src={pi2} alt="" />
                </div>
            </>;

        if (categoriaSelecionada === 'CS')
            imgs = <>
                <div class="categoria-imagens">
                    <img width="80%" src={cs1} alt="" />
                </div>

                <div class="categoria-imagens">
                    <img width="80%" src={cs2} alt="" />
                </div>
            </>;

        if (categoriaSelecionada === 'SS')
            imgs = <>
                <div class="categoria-imagens">
                    <img width="80%" src={ss1} alt="" />
                </div>

                <div class="categoria-imagens">
                    <img width="80%" src={ss2} alt="" />
                </div>
            </>;

        if (categoriaSelecionada === 'IC')
            imgs = <>
                <div class="categoria-imagens">
                    <img width="80%" src={ic1} alt="" />
                </div>

                <div class="categoria-imagens">
                    <img width="80%" src={ic2} alt="" />
                </div>
            </>;

        if (categoriaSelecionada === 'PG')
            imgs = <>
                <div class="categoria-imagens">
                    <img width="80%" src={pg1} alt="" />
                </div>

                <div class="categoria-imagens">
                    <img width="80%" src={pg2} alt="" />
                </div>
            </>;

        return (<div class="container-categoria-imagens">
            {imgs}


        </div>);

    }
    return (<>
        <section class="categorias">
            <h2 class="categorias-titulo">Conheça as categorias do Prêmio</h2>


            <div class="container-icons-diferenciais">

                <div class="container-seta container-seta-direita"  onClick={()=>sliderCategorias(1)}></div>
                <div class="container-seta container-seta-esquerda" onClick={()=>sliderCategorias(-1)}></div>
                <div class="seta seta-direita" onClick={() => sliderCategorias(1)}>
                    <img src="assets/img/seta-swiper.png" />
                </div>
                <div class="seta seta-esquerda" onClick={() => sliderCategorias(-1)}>
                    <img src="assets/img/seta-swiper.png"  />
                </div>

                <div class="swiper-mobile swiper mySwiper">
                    <div class="swiper-wrapper">
                        {

                            indexCategoria == 0 ? (
                                <div class="swiper-slide">
                                    <div class={categoriaSelecionada === 'PI' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                        <div class="moldura-icon">
                                            <img src={categoriaSelecionada === 'PI' ? require("../../assets/img/icones-categorias/icone-produtividade-active.png") : require("../../assets/img/icones-categorias/icone-produtividade.png")} alt="" />
                                        </div>
                                        <span>Produtividade e inovação</span>
                                    </div>
                                </div>) : null
                        }
                        {
                            indexCategoria == 1 ? (<div class="swiper-slide">
                                <div class={categoriaSelecionada === 'CS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                    <div class="moldura-icon">
                                        <img src={categoriaSelecionada === 'CS' ? require("../../assets/img/icones-categorias/icone-crescimento-active.png") : require("../../assets/img/icones-categorias/icone-crescimento.png")} alt="" />
                                    </div>
                                    <span>Crescimento sustentável</span>
                                </div>
                            </div>) : null
                        }
                        {
                            indexCategoria == 2 ?
                                (<div class="swiper-slide">
                                    <div class={categoriaSelecionada === 'SS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                        <div class="moldura-icon">
                                            <img src={categoriaSelecionada === 'SS' ? require("../../assets/img/icones-categorias/icone-saude-active.png") : require("../../assets/img/icones-categorias/icone-saude.png")} alt="" />
                                        </div>
                                        <span>Saúde e segurança</span>
                                    </div>
                                </div>) : null
                        }
                        {
                            indexCategoria == 3 ?
                                (<div class="swiper-slide">
                                    <div class={categoriaSelecionada === 'IC' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                        <div class="moldura-icon">
                                            <img src={categoriaSelecionada === 'IC' ? require("../../assets/img/icones-categorias/icone-iniciativas-active.png") : require("../../assets/img/icones-categorias/icone-iniciativas.png")} alt="" />
                                        </div>
                                        <span>Iniciativas corporativas</span>
                                    </div>
                                </div>
                                ) : null
                        }
                        {
                            indexCategoria == 4 ? (
                                <div class="swiper-slide">
                                    <div class={categoriaSelecionada === 'PG' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                        <div class="moldura-icon">
                                            <img src={categoriaSelecionada === 'PG' ? require("../../assets/img/icones-categorias/icone-acoes-active.png") : require("../../assets/img/icones-categorias/icone-acoes.png")} alt="" />
                                        </div>
                                        <span>Pequenas ações<br/> grandes feitos</span>
                                    </div>
                                </div>) : null
                        }
                    </div>

                </div>

                <div class="container swiper-desktop swiper">
                    <div class={categoriaSelecionada === 'PI' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('PI')}>
                        <div class="moldura-icon">
                            <img src={categoriaSelecionada === 'PI' ? require("../../assets/img/icones-categorias/icone-produtividade-active.png") : require("../../assets/img/icones-categorias/icone-produtividade.png")} alt="" />
                        </div>
                        <span>Produtividade e inovação</span>
                    </div>
                    <div class={categoriaSelecionada === 'CS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('CS')}>
                        <div class="moldura-icon">
                            <img src={categoriaSelecionada === 'CS' ? require("../../assets/img/icones-categorias/icone-crescimento-active.png") : require("../../assets/img/icones-categorias/icone-crescimento.png")} alt="" />
                        </div>
                        <span>Crescimento sustentável</span>
                    </div>
                    <div class={categoriaSelecionada === 'SS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('SS')}>
                        <div class="moldura-icon">
                            <img src={categoriaSelecionada === 'SS' ? require("../../assets/img/icones-categorias/icone-saude-active.png") : require("../../assets/img/icones-categorias/icone-saude.png")}  alt="" />
                        </div>
                        <span>Saúde e segurança</span>
                    </div>
                    <div class={categoriaSelecionada === 'IC' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('IC')}>
                        <div class="moldura-icon">
                            <img src={categoriaSelecionada === 'IC' ? require("../../assets/img/icones-categorias/icone-iniciativas-active.png") : require("../../assets/img/icones-categorias/icone-iniciativas.png")} alt="" />
                        </div>
                        <span>Iniciativas corporativas</span>
                    </div>
                    <div class={categoriaSelecionada === 'PG' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('PG')}>
                        <div class="moldura-icon">
                            <img src={categoriaSelecionada === 'PG' ? require("../../assets/img/icones-categorias/icone-acoes-active.png") : require("../../assets/img/icones-categorias/icone-acoes.png")} alt="" />
                        </div>
                        <span>Pequenas ações grandes feitos</span>
                    </div>
                </div>


            </div>

            <img class={"categoria-selecionado-" + categoriaSelecionada} src="assets/img/categoria-selecionado.png" />
            <div class="container container-categoria">

                {imgsCategorias()}

                {/*categorias.length > 0 ? <div class="container-categoria-imagens">
				
					<div class="categoria-imagens">
                        <img width="85%" src={require(`../../assets/img/categorias/${categorias.find(x => x.codigo === categoriaSelecionada)?.img1}`)} alt=""/>
					</div>

                    <div class="categoria-imagens">
                        <img width="85%" src={require(`../../assets/img/categorias/${categorias.find(x => x.codigo === categoriaSelecionada)?.img2}`)} alt="" />
					</div>

                </div> : null
                */}
				

                <div class="container-categoria-texto">
                    <h2>{categorias.find(x => x.codigo === categoriaSelecionada)?.descricao}</h2>
                    <p><div dangerouslySetInnerHTML={{ __html: categorias.find(x => x.codigo === categoriaSelecionada)?.texto }} /></p>
                </div>
            </div>
        </section>

    </>);
}