import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { IconButton, TextField, DatePicker, Grid, styled, Paper } from '@material-ui/core';
import votoService from '../../services/voto/voto-service';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function VotoAdmPage(props) {

    const [usuarios, setUsuarios] = useState([]);
    const [dialogPerfil, setDialogPerfil] = useState(false);
    const [idUsuario, setIdUsuario] = useState(null);
    const [configuracoes, setConfiguracoes] = useState([]);
    const [votos, setVotos] = useState([]);

    useEffect(() => {
        init();
    }, []);


    const init = async () => {

        await getVotos();

    }

    const getVotos = async () => {
        const response = await votoService.votos();

        if (response.status === 200) {
            const _votos = await response.json()
            setVotos(_votos);
        }
    }


    return (<>
     
        <MaterialTable
            title={'Votos'}
            columns={[
                { title: 'Titulo', field: 'titulo', editable: 'never' },
                { title: 'Nome', field: 'descricao', editable: 'never' },
                { title: 'Unidade', field: 'unidade' },
                { title: 'Area', field: 'area' },
                { title: 'Lider', field: 'lider' },
                { title: 'Categoria', field: 'categoria' },
                { title: 'Votos', field: 'qtde_voto' },

            ]}
            data={votos}
        />

    </>);
}