class TrabalhoService {
    async cadastrar(cadastro) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/trabalhos`,
                {
                    method: 'POST',
                    body: cadastro,
                  
                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

    async responderTriagem(idTrabalho, arquivo) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/trabalhos/triagem/${idTrabalho}`,
                {
                    method: 'PUT',
                    body: arquivo,

                });

            return response;

        } catch (error) {
            console.log(error)
        }
    }

    async trabalhos() {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/trabalhos`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            if (response.status === 200)
                return await response.json();
        } catch (error) {
            console.log(error)
        }
    }

    async trabalhosByStatus(status) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/trabalhos/status/${status}`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            if (response.status === 200)
                return await response.json();
        } catch (error) {
            console.log(error)
        }
    }

    async trabalhosByUsuario(idUsuario) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/trabalhos/${idUsuario}`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            if (response.status === 200)
                return await response.json();
        } catch (error) {
            console.log(error)
        }
    }

    async anexo(idTrabalho) {

        // let _headers = AuthService.getAuthHeader();
        try {
            let response = await fetch(`api/anexos/${idTrabalho}`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

            if (response.status === 200)
                return await response.json();

        } catch (error) {
            console.log(error)
        }
    }

}
export default new TrabalhoService();