import React, { Component, useEffect } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import HomePage from './pages/home/home-page';
import LoginPage from './pages/login/login-page';
import CategoriaPage from './pages/categoria/categoria-page';
import DuvidaPage from './pages/duvida/duvida-page';
import RegulamentoPage from './pages/regulamento/regulamento-page';
import InscrevasePage from './pages/inscrevase/inscrevase-page';
import EspacoAvaliadorPage from './pages/avaliador/espaco-avaliador-page';
import UsuarioPage from './pages/usuario/usuario-page';
import UsuarioAdmPage from './pages/adm/usuarios-adm-page';
import VotacaoPage from './pages/votacao/votacao-page';
import ConfigPage from './pages/adm/config-page';
import admPage from './pages/adm/adm-page';

export default function App(){


  
    return (
      <Layout>
            <Route exact path='/' component={HomePage} />
            <Route exact path='/login' component={LoginPage} />
            <Route exact path='/categorias' component={CategoriaPage} />
            <Route exact path='/duvidas' component={DuvidaPage} />
            <Route exact path='/regulamentos' component={RegulamentoPage} />
            <Route exact path='/inscrevase' component={InscrevasePage} />
            <Route exact path='/avaliador' component={EspacoAvaliadorPage} />
            <Route exact path='/usuario' component={UsuarioPage}/>
            <Route exact path='/admuser' component={UsuarioAdmPage} />
            <Route exact path='/votacao' component={VotacaoPage} />
            <Route exact path='/configuracao' component={ConfigPage} />
            <Route exact path='/adm' component={admPage} />
      </Layout>
    );
  
}
